import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/shared/models';

export const actionTypes: ObjectWithStringValues = {
  TOGGLE_FIRST_TIME_DRAW: 'TOGGLE_FIRST_TIME_DRAW',
  TOGGLE_FIRST_TIME_REACH_BOX_LIMIT: 'TOGGLE_FIRST_TIME_REACH_BOX_LIMIT'
};

export class ToggleFirstTimeDraw implements Action {
    readonly type = actionTypes.TOGGLE_FIRST_TIME_DRAW;

    constructor() { }
}

export class ToggleFirstTimeReachBoxLimit implements Action {
    readonly type = actionTypes.TOGGLE_FIRST_TIME_REACH_BOX_LIMIT;

    constructor() { }
}
