import { handleActions } from 'redux-actions';
import {
  MyChartItemState,
  MyChartItem,
  DeleteChartItemsResponse,
  MyChartItemChartData
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';
import {
  actionTypes, GetChartItemAction
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.actions';
import { rejected, RejectedAction, resolved, ResolvedAction } from '@app/core/features/base';
import update from 'immutability-helper';
import { areaOfInterestType } from '@app/constants';

const initialState: MyChartItemState = {
  items: {},
  information: null,
  chartData: {
    data: {},
    isFetching: false,
    error: null,
  },
  chartAerials: {
    data: {},
    isFetching: false,
    error: null,
  },
  chartPoints: {
    data: {},
    isFetching: false,
    error: null,
  },
  migrationOptions: {
    data: [],
    isFetching: false,
    error: null
  }
};
export const reducer = handleActions({
  [resolved(actionTypes.GET_CHART_ENTITLEMENT_BY_ID)]: (state: MyChartItemState, action: ResolvedAction) => {
    const { payload: { data }} = action;
    return update(state, {
        information: {
          $set: data
        }
    });
  },
  [resolved(actionTypes.GET_CHART_ITEMS)]: (state: MyChartItemState, action: ResolvedAction) => {
    const { payload: { data }, meta: { chartEntitlementId } } = action;
    return update(state, {
        items: {
          [chartEntitlementId]: { $set: data }
        }
    });
  },
  [resolved(actionTypes.CREATE_CHART_ITEM)]: (state: MyChartItemState, action: ResolvedAction) => {
    const {payload: {data}, meta: {chartEntitlementId, aoiType}} = action;
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return update(state, {
          chartData: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      case areaOfInterestType.AERIAL_PHOTOS:
        return update(state, {
          chartAerials: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      case areaOfInterestType.POI:
        return update(state, {
          chartPoints: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      default:
        break;
    }
  },
  [resolved(actionTypes.DELETE_CHART_ITEM_ONE)]: (state: MyChartItemState, action: ResolvedAction) => {
    const {payload: { data: { chartData, chartEntitlementId, aoiType }} } = action;
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return update(state, {
          chartData: {
            data: {
              [chartEntitlementId]: {
                $apply: (data: MyChartItemChartData[]): MyChartItemChartData[] => data.filter(item => item.id !== chartData.id)
              }
            }
          }
        });
      case areaOfInterestType.AERIAL_PHOTOS:
        return update(state, {
          chartAerials: {
            data: {
              [chartEntitlementId]: {
                $apply: (data: MyChartItemChartData[]): MyChartItemChartData[] => data.filter(item => item.id !== chartData.id)
              }
            }
          }
        });
      case areaOfInterestType.POI:
        return update(state, {
          chartPoints: {
            data: {
              [chartEntitlementId]: {
                $apply: (data: MyChartItemChartData[]): MyChartItemChartData[] => data.filter(item => item.id !== chartData.id)
              }
            }
          }
        });
      default:
        break;
    }
  },
  [resolved(actionTypes.DELETE_CHART_ITEM_ALL)]: (state: MyChartItemState, action: ResolvedAction) => {
    const { meta: { chartEntitlementId, aoiType }} = action;
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return update(state, {
          chartData: {
            data: {
              $unset: [chartEntitlementId]
            }
          }
        });
      case areaOfInterestType.AERIAL_PHOTOS:
        return update(state, {
          chartAerials: {
            data: {
              $unset: [chartEntitlementId]
            }
          }
        });
      case areaOfInterestType.POI:
        return update(state, {
          chartPoints: {
            data: {
              $unset: [chartEntitlementId]
            }
          }
        });
      default:
        break;
    }
  },
  [resolved(actionTypes.DELETE_CHART_ITEMS)]: (state: MyChartItemState, action: ResolvedAction) => {
    const deleteChartItemsResponse = action.payload.data as DeleteChartItemsResponse;
    return update(state, {
        items: {
          [deleteChartItemsResponse.entitlementId]: {
            $apply: (items: MyChartItem[]): MyChartItem[] => items.filter(item => !deleteChartItemsResponse.ids.includes(item.id))
          }
        }
    });
  },
  [resolved(actionTypes.CREATE_ENTIRE)]: (state: MyChartItemState, action: ResolvedAction) => {
    const { payload: { data }, meta: { chartEntitlementId } } = action;
    return update(state, {
      items: {
        [chartEntitlementId]: { $set: [data] }
      }
    });
  },
  [rejected(actionTypes.CREATE_CHART_ITEM_CHART_DATA)]: (state: MyChartItemState, action: RejectedAction) => {
    const {payload: {error}} = action;
    return update(state, {
      chartData: {
        error: {$set: error}
      }
    });
  },
  [actionTypes.GET_CHART_ITEM]: (state: MyChartItemState, action: GetChartItemAction) => {
    const {payload: { aoiType }} = action;
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return update(state, {
          chartData: {
            isFetching: {$set: true}
          }
        });
      case areaOfInterestType.AERIAL_PHOTOS:
        return update(state, {
          chartAerials: {
            isFetching: {$set: true}
          }
        });
      case areaOfInterestType.POI:
        return update(state, {
          chartPoints: {
            isFetching: {$set: true}
          }
        });
      default:
        break;
    }
  },
  [resolved(actionTypes.GET_CHART_ITEM)]: (state: MyChartItemState, action: ResolvedAction) => {
    const {payload: {data}, meta: {chartEntitlementId, aoiType}} = action;
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return update(state, {
          chartData: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      case areaOfInterestType.AERIAL_PHOTOS:
        return update(state, {
          chartAerials: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      case areaOfInterestType.POI:
        return update(state, {
          chartPoints: {
            data: {
              [chartEntitlementId]: {$set: data}
            },
            isFetching: {$set: false}
          }
        });
      default:
        break;
    }
  },
  [actionTypes.GET_MIGRATION_OPTIONS]: (state: MyChartItemState, action: ResolvedAction) => {
    return update(state, {
      migrationOptions: {
          isFetching: {$set: true},
          error: {}
      }
  });
  },
  [resolved(actionTypes.GET_MIGRATION_OPTIONS)]: (state: MyChartItemState, action: ResolvedAction) => {
    const {payload: { data }, meta: {chartEntitlementIds}} = action;
    return update(state, {
      migrationOptions: {
          data: {$set: data },
          isFetching: {$set: false},
          error: {}
      }
  });
  },
  [rejected(actionTypes.GET_MIGRATION_OPTIONS)]: (state: MyChartItemState, action: ResolvedAction) => {
    return update(state, {
      migrationOptions: {
          isFetching: {$set: false},
          error: {}
      }
  });
  },
}, initialState);
