import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { State } from '@app/core/features';
import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { MyChartsListService } from './my-charts-list.service';
import {
  MyChartList,
  actionTypes
} from './my-charts-list.actions';

@Injectable()
export class MyChartsListEffects extends BaseEffects {
  constructor(
    private myChartsListService: MyChartsListService,
    protected actions: Actions,
    protected store: Store<State>
  ) {
    super();
  }

  @Effect()
  myChartList: Observable<Action> = this.takeAction<MyChartList>(
    actionTypes.MY_CHART_LIST,
    this.handleGetMyChart,
  );

  private handleGetMyChart(action: MyChartList): Observable<ResolvedAction | RejectedAction> {
    const { type, isDealer } = action;
    return this.handleCommonApiRequest(type)(this.myChartsListService.getMyChartsList, isDealer);
  }
}


