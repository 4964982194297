import { InjectionToken } from '@angular/core';
import { storeFreeze } from 'ngrx-store-freeze';
import { ActionReducerMap, MetaReducer, Action } from '@ngrx/store';
import { environment } from '@env/index';
import { State } from './models';
import { reducer as authReducer } from './auth';
import { reducer as chartsStoreReducer } from './charts-store';
import { reducer as myChartsReducer } from './charts-manager/my-charts';
import { reducer as productsReducer } from './product/product.reducers';
import { reducer as paymentReducer } from './payment/payment.reducer';
import { reducer as subscriptionsReducer } from './plan-subscription/plan-subscription.reducers';
import { reducer as maintenanceModeReducer } from './maintenance-mode/maintenance-mode.reducers';

export const reducer: ActionReducerMap<State, Action> = {
  auth: authReducer,
  myCharts: myChartsReducer,
  chartsStoreState: chartsStoreReducer,
  products: productsReducer,
  payment: paymentReducer,
  subscription: subscriptionsReducer,
  maintenanceMode: maintenanceModeReducer
};

export const metaReducers: MetaReducer<State, Action>[] = !environment.production ? [
  storeFreeze
] : [];

export const reducerToken = new InjectionToken('reducer');

export const reducerProvider = [
  { provide: reducerToken, useValue: reducer }
];
