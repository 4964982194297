// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapboxToken: 'pk.eyJ1IjoiZGV2ZWxvcGVyLWZpc2hpZHkiLCJhIjoiY2s5eDRreTB3MGc4YzNlcG1kYzF3Z2gwcSJ9.38nYKHMQ70xldlDLtBazEA',
  baseApiUrl: 'https://stage-chartstoreapi.fishidy.com/api',
  gisApiUrl: 'https://stage-gisapi.fishidy.com',
  ssoBaseUrl: 'https://flirb2clab.b2clogin.com/flirb2clab.onmicrosoft.com',
  ssoClientid: '8973ea76-422d-407d-947a-fed45f96fef2',
  ssoSignUpSignIn: 'B2C_1A_CORE_SignupSignIn',
  ssoResetPassword: 'B2C_1A_Core_PasswordReset',
  ssoEditProfile: 'B2C_1A_CORE_ProfileEdit',
  axiomChartManagerFlag: '1',
  axiomChartStoreFlag: '0',
  intervalInSecond: 10,
  imageUrl: 'https://stage-chartmanager.raymarine.com/static-images/',
  mapboxStylePrefix: 'mapbox://styles/',
  mapboxStyleUrl: 'developer-fishidy/ckh3m7m7t01vw19rwjmpaykfl',
  mapboxSatellite: 'mapbox/satellite-streets-v11',
  defaultFreeNAProductSKU: 'LHC-NAM-001',
  numberOfFreeChart: 1,
  lengthOfVoucherCode: 9,
  googleAnalyticsId: 'G-RM506YDYST',
  widthForMobileView: 1024,
  proportionalValue: 4,
  domainCookies: '.raymarine.com',
  exceedSizeLimitInGigabyte: 10,
  rayconnectAppleStoreLink: 'https://apps.apple.com/gb/app/rayconnect-by-raymarine/id1502278726',
  rayconnectGoogleStoreLink: 'https://play.google.com/store/apps/details?id=com.raymarine.rayconnect',
  raymarineSupportLink: 'https://teledyneflir.my.site.com/',
  mapboxApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  maximumMergedCharts: 3,
  imageSizeLimit: 3145728,
  defaultBundledProductSku: 'LHC-IGB-001',
  textAreaMaxLength: 250,
  ribbonType: 3,
  mfdSoftwareUpdatesLink: 'https://www.raymarine.eu/service-and-support/software-updates/',
  worldpayGateway: "7202",
  zuoraCheckout3dsEnabledPageId: '8ad09ea08736ff3a01874e2ddbfa5ec4',
  zuoraCheckout3dsDisabledPageId: '8ad088718561b84d01857e13b2c61ba0',
  zuoraHostedPage: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
  zuoraTermsConditions: 'https://www.zuora.com/terms-conditions/',
  monikerImageUrl: 'moniker-images/'
};
