import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  UserData,
  usersResponseConvertSchema,
  FeedbackDetailPayload,
  FeedbackDetail,
} from './auth.models';
import { MsalGuardAuthRequest, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { endpoints } from '@app/constants';
import { pickModelValues } from '@app/utils';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpService } from '@app/core/services/http/http.service';
import { AccountInfo, AuthenticationResult, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { environment } from '@env/index';
import { PaymentService } from '../payment/payment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  request: MsalGuardAuthRequest;
  authentication_result: AuthenticationResult;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private http: HttpService,
    private authService: MsalService
  ) {
    this.request = {
      scopes: [
          'openid',
          environment.ssoClientid,
          'offline_access',
      ],
      extraQueryParameters: { branding: 'raymarine' }
    };
  }

  // Observable user data
  private userData = new BehaviorSubject<UserData>({} as UserData);

  // Observable user data stream
  userData$ = this.userData.asObservable();

  // Update the data user
  updateUserData = (data: UserData): void => this.userData.next(data);

  getUserDataValue = (): UserData => this.userData.value;
  
  checkoutAccount = (): boolean => this.authService.instance.getAllAccounts().length > 0;

  checkoutActiveAccount = (): boolean => !!this.authService.instance.getActiveAccount();

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    //too many accounts logout and start over
    if(activeAccount != null && this.authService.instance.getAllAccounts().length > 1){
      this.logOut()
    }
    if (activeAccount != null && this.authService.instance.getAllAccounts().length == 1) {
        const accounts = this.authService.instance.getAllAccounts();
        this.setActiveAccount(accounts[0]);
    }
  }

  setActiveAccount(account: AccountInfo) {
    this.authService.instance.setActiveAccount(account);
  }

  editProfile = (): void => {
    this.request.authority = `${environment.ssoBaseUrl}/${environment.ssoEditProfile}`;
    this.authService.loginRedirect({ ...this.request } as RedirectRequest);
  };

  logOut() {
    this.authService.logoutRedirect();
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
        this.authService.loginRedirect({ ...this.request } as RedirectRequest);
    }
  }

  async acquireTokenSilent(): Promise<AuthenticationResult> {
    let token: AuthenticationResult = null;
    if (this.checkoutActiveAccount()) {
        await this.authService.instance.acquireTokenSilent({ ...this.request } as SilentRequest).then((result) => {
            token = result;
        });
    }

    return token;
  }
  fetchLoggedInUser = (): Observable<object> => 
    this.http
      .get({ url: endpoints.getUserInfo })
      .pipe(
        mergeMap(this.handleFetchLoggedInUserResponse),
        catchError((error) => {
          console.error('An error occurred:', error.error);
          return of(error)
        }),
      )

  fetchFeedbackDetail = (payload: FeedbackDetailPayload): Observable<object> =>
    this.http
      .get({ url: `${endpoints.chartFeedback}${endpoints.checkSaveDetail}/${payload.userId}` })
      .pipe(
        mergeMap(this.handleFetchFeedbackDetailResponse),
        catchError((error) => of(error))
      )

  private handleFetchLoggedInUserResponse = (response: UserData): Observable<UserData> => {
    const user = pickModelValues(response, usersResponseConvertSchema) as UserData;

    return of(user);
  }

  private handleFetchFeedbackDetailResponse = (response: FeedbackDetail): Observable<object> => {
    
    return of(response);
  }
}
