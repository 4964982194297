import { State } from '@app/core/features';
import {
    MyChartListState,
    GroupCardsData
} from './my-charts-list.models';
import { createSelector, select } from '@ngrx/store';

const getLocalState = (state: State): MyChartListState => state.myCharts.list;

const isFetching = createSelector(
    getLocalState,
    (state: MyChartListState): boolean => state.isFetching
);

const getList = createSelector(
    getLocalState,
    (state: MyChartListState): GroupCardsData[] => state.data
);

const isEmpty = createSelector(
    getLocalState,
    (state: MyChartListState): boolean => state.isEmpty
);

export const selectors = {
    getList: select(getList),
    isFetching: select(isFetching),
    isEmpty: select(isEmpty)
};
