import { Injectable } from '@angular/core';
import { endpoints } from '@app/constants';
import { HttpService } from '@app/core/services/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AccountSummary, GetAnAccountSummaryPayload, GetListCreditCardsPayload, ListCreditCardsSummary, ListInvoicesResponse, ZuoraAccount } from './account-zuora.models';

@Injectable({
  providedIn: 'root'
})
export class AccountZuoraService {
  constructor(
    private httpService: HttpService,
  ) { }

  public getAnAccountSummary = (payload: GetAnAccountSummaryPayload): Observable<AccountSummary> => {
    return this.httpService
    .get({ url: `${endpoints.zuora}${endpoints.accountSummary}${payload.accountNumber}` })
    .pipe(
        mergeMap(this.handleGetAnAccountSummaryResponse),
        catchError(err => {
          return throwError(err);
        })
    );
  }

  public getListAccountSummary = (): Observable<AccountSummary[]> => {
    return this.httpService
    .get({ url: `${endpoints.zuora}${endpoints.account}${endpoints.summary}` })
    .pipe(
        mergeMap(this.handleGetListAccountSummaryResponse),
        catchError(err => {
          return throwError(err);
        })
    );
  }

  public getListCreditCards = (payload: GetListCreditCardsPayload): Observable<ListCreditCardsSummary> => {
    return this.httpService
      .get({ url: `${endpoints.zuora}${endpoints.paymentMethod}${endpoints.getListPaymentMethod}${payload.accountNumber}` })
      .pipe(
        mergeMap(this.handleGetListCreditCards),
        catchError(err => {
          return throwError(err);
        })
    );
  }

  public getListZuoraAccount = (): Observable<ZuoraAccount[]> => {
    return this.httpService
      .get({ url: `${endpoints.zuora}${endpoints.account}${endpoints.user}` })
      .pipe(
        mergeMap(this.handleGetListZuoraAccount),
        catchError(err => {
          return throwError(err);
        })
    );
  }

  public downloadInvoice = (invoiceNumber: string): Observable<any> => {
    return this.httpService
      .setResponseType('blob')
      .get({ url: endpoints.zuora + endpoints.getInvoiceFile + invoiceNumber })
      .pipe(
        mergeMap(this.handleDownloadInvoiceFile),
        catchError((error) => of(error)),
      );
  }

  public getListInvoice = (accountKey: string): Observable<ListInvoicesResponse> => {
    return this.httpService
      .get({ url: endpoints.zuora + endpoints.invoices + endpoints.accounts + accountKey })
      .pipe(
        mergeMap(this.handleGetListInvoices),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private handleGetAnAccountSummaryResponse(response: AccountSummary): Observable<AccountSummary> {
    return of(response);
  }

  private handleGetListAccountSummaryResponse(response: AccountSummary[]): Observable<AccountSummary[]> {
    return of(response);
  }

  private handleGetListCreditCards(response: ListCreditCardsSummary): Observable<ListCreditCardsSummary> {
    return of(response);
  } 

  private handleGetListZuoraAccount(response: ZuoraAccount[]): Observable<ZuoraAccount[]> {
    return of(response);
  } 

  private handleDownloadInvoiceFile(response: any): Observable<any> {
    return of(response);
  }

  private handleGetListInvoices(response: ListInvoicesResponse): Observable<ListInvoicesResponse> {
    return of(response);
  }
}
