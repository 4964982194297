import { MyChartsEffects } from '@app/core/features/charts-manager/my-charts';
import { ChartsStoreEffects } from './charts-store';
import { AuthEffects } from './auth';
import { ProductsEffects } from './product/product.effects';
import { PaymentEffects } from './payment';
import { SubscriptionEffect } from './plan-subscription/plan-subscription.effects'
import { MaintenanceModeEffects } from './maintenance-mode';

export const effects = [
  ...MyChartsEffects,
  ...PaymentEffects,
  ChartsStoreEffects,
  AuthEffects,
  ProductsEffects,
  SubscriptionEffect,
  MaintenanceModeEffects
];
