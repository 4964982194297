
export const VIDEO_SOURCE = {
    FIRST_DRAW: [
        'assets/videos/draw_01_and_02.mp4',
    ],
    FIRST_REACH_BOX_LIMIT: [
        'assets/videos/draw_01_and_02.mp4',
        'assets/videos/reach_box_01.mp4',
        'assets/videos/reach_box_02.mp4',
    ]
};
