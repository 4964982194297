import {handleActions} from 'redux-actions';
import update from 'immutability-helper';

import {resolved, rejected, ResolvedAction, RejectedAction} from '@app/core/features/base';
import {ChartsStoreState} from './charts-store.models';
import {actionTypes} from './charts-store.actions';

const initialState: ChartsStoreState = {
  data: null,
  isFetching: false,
  error: null,
  isEmpty: false
};

export const reducer = handleActions({
  [actionTypes.CHARTS_STORE]: (state: ChartsStoreState) => {
    return update(state, {
      isFetching: {$set: true},
      error: {$set: null}
    });
  },
  [resolved(actionTypes.CHARTS_STORE)]: (state: ChartsStoreState, action: ResolvedAction) => {
    const {payload: {data}} = action;
    return update(state, {
      data: {$set: data},
      isFetching: {$set: false},
      isEmpty: {$set: !data}
    });
  },
  [rejected(actionTypes.CHARTS_STORE)]: (state: ChartsStoreState, action: RejectedAction) => {
    const {payload: {error}} = action;
    return update(state, {
      error: {$set: error},
      isFetching: {$set: false}
    });
  },
}, initialState);
