import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { endpoints, ChartType, FreeType, constants } from '@app/constants/';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpService } from '@app/core/services/http/http.service';
import {
  GetProductRequest,
  MyChartsListResponse,
  CardsData, Product,
  GroupCardsData,
  removeLighthouseText,
  DownloadStep,
  ListAssignEntitlement,
  ChartEntitlementDto,
  UpdateChartNotesPayload
} from './my-charts-list.models';
import { PolygonData } from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';
import { ADD_TO_CART } from '@app/constants/add-to-cart';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class MyChartsListService {
  type = FreeType;

  constructor(
    private http: HttpService,
    private cookieService: CookieService,
  ) { }

  getMyChartsList = (isDealer = false): Observable<GroupCardsData[]> => {
    if (isDealer) {
      return this.http
      .get({ url: endpoints.getMyChartsListDealer })
      .pipe(
        mergeMap((response: MyChartsListResponse[]) => {
          return this.handleMyChartsResponseToCardsDataDealer(response);
        }),
        catchError((error) => throwError(error))
      );
    } else {
      return this.http
      .get({ url: endpoints.getMyChartsList })
      .pipe(
        mergeMap((response: ChartEntitlementDto[]) => {
          return this.handleMyChartsResponseToCardsData(response);
        }),
        catchError((error) => throwError(error))
      );
    }
  }

  getPolygonOfProduct = (request: GetProductRequest): Observable<object> => {
    return this.http
      .get({ url: endpoints.getProduct + '/' + request.productId })
      .pipe(
        mergeMap(this.handleProductToPolygon),
        catchError((error) => throwError(error))
      );
  }

  private handleMyChartsResponseToCardsData(
    response: ChartEntitlementDto[]): Observable<GroupCardsData[]> {

    const groupCardList: GroupCardsData[] = [];
    response.forEach(item => {
      let isPremium = false;
      if (item.premiumProductEntitlement && item.premiumProductEntitlement.id) {
        isPremium = true;
      }

      const cardItem: CardsData = {
        isPremium,
        chartEntitlementId: item.id,
        baseEntitlementId: item.baseProductEntitlement.id,
        lighthouseId: item.userDevice !== null ? item.userDevice.lighthouseId : '',
        errorCode: item.userDevice !== null ? item.userDevice.errorCode : '',
        packagedChartName: item.userDevice 
          ? (item.userDevice.latestPackageChartName ? item.userDevice.latestPackageChartName : constants.defaultChartName) 
          : '',
        packagedChartDateTime: item.userDevice !== null ? item.userDevice.latestPackageSuccess : '',
        premiumEntitlementId: isPremium
          ? item.premiumProductEntitlement.id
          : item.baseProductEntitlement.id,
        productId: item.baseProductEntitlement.productId,
        productName: removeLighthouseText(item.baseProductEntitlement.product.name),
        productFullName: isPremium
          ? item.premiumProductEntitlement.product.name
          : item.baseProductEntitlement.product.name,
        productSizeKb: item.baseProductEntitlement.product.chartSizeKb,
        baseMapAndCoverageSizeKb: item.baseProductEntitlement.product.baseMapAndCoverageSizeKb ?
          item.baseProductEntitlement.product.baseMapAndCoverageSizeKb : 0,
        baseChartEstimateSizeKb: item.baseProductEntitlement.product.baseChartEstimateSizeKb ?
          item.baseProductEntitlement.product.baseChartEstimateSizeKb : 0,
        productLastUpdatedDateTime: item.baseProductEntitlement.product.lastUpdatedDateTime,
        cardType: item.baseProductEntitlement.product.chartType,
        purchasedDateTime: item.baseProductEntitlement.purchasedDateTime,
        expiresDateTime: item.baseProductEntitlement.expiresDateTime,
        isExpired: item.isExpired,
        isRedeemed: item.consumedDateTime !== null,
        activeImage: item.baseProductEntitlement.product.activeImage ? item.baseProductEntitlement.product.activeImage : item.baseProductEntitlement.product.activeImagePng,
        inactiveImage: item.baseProductEntitlement.product.inactiveImage ? item.baseProductEntitlement.product.inactiveImage : item.baseProductEntitlement.product.inactiveImagePng,
        isUpdateAvailable: item.updateAvailable,
        isIncludeInDownload: item.includeInDownload,
        isNew: item.isNew,
        sku: item.baseProductEntitlement.product.sku,
        skuPremium: item.baseProductEntitlement.product.premiumProduct !== null
          ? item.baseProductEntitlement.product.premiumProduct.sku
          : '',
        version: item.baseProductEntitlement.product.version,
        areaOfInterestDtos: isPremium
          ? [
              ...item.baseProductEntitlement.areaOfInterestDtos,
              ...item.premiumProductEntitlement.areaOfInterestDtos
            ]
          : item.baseProductEntitlement.areaOfInterestDtos,
        isTrial: item.isTrial,
        unavailable: false,
        mergeGroup: item.baseProductEntitlement.product.mergeGroup,
        notes: item.notes,
        chartDownloadDate: item.chartDownloadDate,
        voucherCode: item.voucherCode,
        warning: item.baseProductEntitlement.product.warning,
        nhoUpdateDate: item.nhoUpdateDate,
        voucherType: item.voucherType,
        termDays: isPremium
          ? item.premiumProductEntitlement.product.termDays
          : 0,
          packagedChartVersion: item.lastDownloadedProductVersion,
          smallChartDownloadedAcknowledged: item.smallChartDownloadedAcknowledged,
          partNumber: item.baseProductEntitlement.product.partNumber
      };
      const chartEntitlementAssign = {} as ListAssignEntitlement;
      chartEntitlementAssign.name = cardItem.productName;
      chartEntitlementAssign.produceId = cardItem.productId;
      chartEntitlementAssign.mergeGroup = cardItem.mergeGroup;

      if (cardItem.cardType === ChartType.RX6) {
        const groupCardItem: GroupCardsData = {
          chartEntitlementId: cardItem.chartEntitlementId,
          cardType: cardItem.cardType,
          lighthouseId: cardItem.lighthouseId,
          isTrial: cardItem.isTrial,
          listChildCardsData: [cardItem],
          listChildChartEntitlementId: [cardItem.chartEntitlementId],
          listChildChartEntitlementName: [cardItem.productName],
          listChartEntitlementAssign: [chartEntitlementAssign],
          notes: cardItem.notes
        };
        groupCardList.push(groupCardItem);
      } else {
        if (item.userDevice === null) {
          const groupCardItem: GroupCardsData = {
            chartEntitlementId: cardItem.chartEntitlementId,
            cardType: cardItem.cardType,
            lighthouseId: cardItem.lighthouseId,
            isTrial: cardItem.isTrial,
            listChildCardsData: [cardItem],
            listChildChartEntitlementId: [cardItem.chartEntitlementId],
            listChildChartEntitlementName: [cardItem.productName],
            listChartEntitlementAssign: [chartEntitlementAssign],
            notes: cardItem.notes
          };
          groupCardList.push(groupCardItem);
        } else {
          const checkExist = groupCardList.findIndex(el => el.cardType === ChartType.LIGHT_HOUSE_CHART
            && (el.lighthouseId === cardItem.lighthouseId));

          if (checkExist === -1) {
            const groupCardItem: GroupCardsData = {
              chartEntitlementId: cardItem.chartEntitlementId,
              cardType: cardItem.cardType,
              lighthouseId: cardItem.lighthouseId,
              isTrial: cardItem.isTrial,
              listChildCardsData: [cardItem],
              listChildChartEntitlementId: [cardItem.chartEntitlementId],
              listChildChartEntitlementName: [cardItem.productName],
              listChartEntitlementAssign: [chartEntitlementAssign],
              notes: cardItem.notes
            };

            groupCardList.push(groupCardItem);
          } else {
            groupCardList[checkExist].listChildCardsData.push(cardItem);
            groupCardList[checkExist].listChildChartEntitlementId.push(cardItem.chartEntitlementId);
            groupCardList[checkExist].listChildChartEntitlementName.push(cardItem.productName);
            groupCardList[checkExist].listChartEntitlementAssign.push(chartEntitlementAssign);
          }
        }
      }
    });

    return of(groupCardList);
  }

  private handleMyChartsResponseToCardsDataDealer(
    response: MyChartsListResponse[]): Observable<GroupCardsData[]> {

    const groupCardList: GroupCardsData[] = [];
    response.forEach(item => {
      let isPremium = false;
      if (item.chartEntitlementDto.premiumProductEntitlement && item.chartEntitlementDto.premiumProductEntitlement.id) {
        isPremium = true;
      }

      const cardItem: CardsData = {
        isPremium,
        chartEntitlementId: item.chartEntitlementDto.id,
        baseEntitlementId: item.chartEntitlementDto.baseProductEntitlement.id,
        lighthouseId: item.chartEntitlementDto.userDevice !== null ? item.chartEntitlementDto.userDevice.lighthouseId : '',
        errorCode: item.chartEntitlementDto.userDevice !== null ? item.chartEntitlementDto.userDevice.errorCode : '',
        packagedChartName: item.chartEntitlementDto.userDevice 
          ? (item.chartEntitlementDto.userDevice.latestPackageChartName ? item.chartEntitlementDto.userDevice.latestPackageChartName : constants.defaultChartName) 
          : '',
        packagedChartDateTime: item.chartEntitlementDto.userDevice !== null ? item.chartEntitlementDto.userDevice.latestPackageSuccess : '',
        premiumEntitlementId: isPremium
          ? item.chartEntitlementDto.premiumProductEntitlement.id
          : item.chartEntitlementDto.baseProductEntitlement.id,
        productId: item.chartEntitlementDto.baseProductEntitlement.productId,
        productName: removeLighthouseText(item.chartEntitlementDto.baseProductEntitlement.product.name),
        productFullName: isPremium
          ? item.chartEntitlementDto.premiumProductEntitlement.product.name
          : item.chartEntitlementDto.baseProductEntitlement.product.name,
        productSizeKb: item.chartEntitlementDto.baseProductEntitlement.product.chartSizeKb,
        baseMapAndCoverageSizeKb: item.chartEntitlementDto.baseProductEntitlement.product.baseMapAndCoverageSizeKb ?
          item.chartEntitlementDto.baseProductEntitlement.product.baseMapAndCoverageSizeKb : 0,
        baseChartEstimateSizeKb: item.chartEntitlementDto.baseProductEntitlement.product.baseChartEstimateSizeKb ?
          item.chartEntitlementDto.baseProductEntitlement.product.baseChartEstimateSizeKb : 0,
        productLastUpdatedDateTime: item.chartEntitlementDto.baseProductEntitlement.product.lastUpdatedDateTime,
        cardType: item.chartEntitlementDto.baseProductEntitlement.product.chartType,
        purchasedDateTime: item.chartEntitlementDto.baseProductEntitlement.purchasedDateTime,
        expiresDateTime: item.chartEntitlementDto.baseProductEntitlement.expiresDateTime,
        isExpired: item.chartEntitlementDto.isExpired,
        isRedeemed: item.chartEntitlementDto.consumedDateTime !== null,
        activeImage: item.chartEntitlementDto.baseProductEntitlement.product.activeImage ? item.chartEntitlementDto.baseProductEntitlement.product.activeImage : item.chartEntitlementDto.baseProductEntitlement.product.activeImagePng,
        inactiveImage: item.chartEntitlementDto.baseProductEntitlement.product.inactiveImage ? item.chartEntitlementDto.baseProductEntitlement.product.inactiveImage : item.chartEntitlementDto.baseProductEntitlement.product.inactiveImagePng,
        isUpdateAvailable: item.chartEntitlementDto.updateAvailable,
        isIncludeInDownload: item.chartEntitlementDto.includeInDownload,
        isNew: item.chartEntitlementDto.isNew,
        sku: item.chartEntitlementDto.baseProductEntitlement.product.sku,
        skuPremium: item.chartEntitlementDto.baseProductEntitlement.product.premiumProduct !== null
          ? item.chartEntitlementDto.baseProductEntitlement.product.premiumProduct.sku
          : '',
        version: item.chartEntitlementDto.baseProductEntitlement.product.version,
        areaOfInterestDtos: isPremium
          ? [
              ...item.chartEntitlementDto.baseProductEntitlement.areaOfInterestDtos,
              ...item.chartEntitlementDto.premiumProductEntitlement.areaOfInterestDtos
            ]
          : item.chartEntitlementDto.baseProductEntitlement.areaOfInterestDtos,
        isTrial: item.chartEntitlementDto.isTrial,
        unavailable: false,
        mergeGroup: item.chartEntitlementDto.baseProductEntitlement.product.mergeGroup,
        transferChartType: item.dealerEntitlementDto ? item.dealerEntitlementDto.transferChartType : null,
        chartCustomName: item.dealerEntitlementDto ? item.dealerEntitlementDto.chartCustomName : null,
        notes: item.chartEntitlementDto.notes,
        warning: item.chartEntitlementDto.baseProductEntitlement.product.warning,
        nhoUpdateDate: item.chartEntitlementDto.nhoUpdateDate ? item.chartEntitlementDto.nhoUpdateDate : null,
        chartDownloadDate: item.chartEntitlementDto.chartDownloadDate,
        voucherType: item.chartEntitlementDto.voucherType,
        voucherCode: item.chartEntitlementDto.voucherCode,
        termDays: isPremium
          ? item.chartEntitlementDto.premiumProductEntitlement.product.termDays
          : 0,
          packagedChartVersion: item.chartEntitlementDto.lastDownloadedProductVersion,
          smallChartDownloadedAcknowledged: item.chartEntitlementDto.smallChartDownloadedAcknowledged,
          partNumber: item.chartEntitlementDto.baseProductEntitlement.product.partNumber,
      };
      const chartEntitlementAssign = {} as ListAssignEntitlement;
      chartEntitlementAssign.name = cardItem.productName;
      chartEntitlementAssign.produceId = cardItem.productId;
      chartEntitlementAssign.mergeGroup = cardItem.mergeGroup;

      if (cardItem.cardType === ChartType.RX6) {
        const groupCardItem: GroupCardsData = {
          chartEntitlementId: cardItem.chartEntitlementId,
          cardType: cardItem.cardType,
          lighthouseId: cardItem.lighthouseId,
          isTrial: cardItem.isTrial,
          listChildCardsData: [cardItem],
          listChildChartEntitlementId: [cardItem.chartEntitlementId],
          listChildChartEntitlementName: [cardItem.productName],
          listChartEntitlementAssign: [chartEntitlementAssign],
          chartTransferType: cardItem.transferChartType,
          chartCustomName: cardItem.chartCustomName,
          notes: cardItem.notes
        };
        groupCardList.push(groupCardItem);
      } else {
        if (item.chartEntitlementDto.userDevice === null) {
          const groupCardItem: GroupCardsData = {
            chartEntitlementId: cardItem.chartEntitlementId,
            cardType: cardItem.cardType,
            lighthouseId: cardItem.lighthouseId,
            isTrial: cardItem.isTrial,
            listChildCardsData: [cardItem],
            listChildChartEntitlementId: [cardItem.chartEntitlementId],
            listChildChartEntitlementName: [cardItem.productName],
            listChartEntitlementAssign: [chartEntitlementAssign],
            chartTransferType: cardItem.transferChartType,
            chartCustomName: cardItem.chartCustomName,
            notes: cardItem.notes
          };
          groupCardList.push(groupCardItem);
        } else {
          const checkExist = groupCardList.findIndex(el => el.cardType === ChartType.LIGHT_HOUSE_CHART
            && (el.lighthouseId === cardItem.lighthouseId));

          if (checkExist === -1) {
            const groupCardItem: GroupCardsData = {
              chartEntitlementId: cardItem.chartEntitlementId,
              cardType: cardItem.cardType,
              lighthouseId: cardItem.lighthouseId,
              isTrial: cardItem.isTrial,
              listChildCardsData: [cardItem],
              listChildChartEntitlementId: [cardItem.chartEntitlementId],
              listChildChartEntitlementName: [cardItem.productName],
              listChartEntitlementAssign: [chartEntitlementAssign],
              chartTransferType: cardItem.transferChartType,
              chartCustomName: cardItem.chartCustomName,
              notes: cardItem.notes
            };

            groupCardList.push(groupCardItem);
          } else {
            groupCardList[checkExist].listChildCardsData.push(cardItem);
            groupCardList[checkExist].listChildChartEntitlementId.push(cardItem.chartEntitlementId);
            groupCardList[checkExist].listChildChartEntitlementName.push(cardItem.productName);
            groupCardList[checkExist].listChartEntitlementAssign.push(chartEntitlementAssign);
          }
        }
      }
    });

    return of(groupCardList);
  }

  private handleProductToPolygon(response: Product): Observable<PolygonData> {
    return of(response.polygon);
  }

  private setListDownloadStep(list: DownloadStep[]) {
    const value = JSON.stringify(list);
    this.cookieService.set(ADD_TO_CART.LIST_DOWNLOAD_STEPS, value, 365, '/');
  }

  private getListDownloadStep(): DownloadStep[] {
    const value = this.cookieService.get(ADD_TO_CART.LIST_DOWNLOAD_STEPS);
    const data = value === '' ? [] : JSON.parse(value);
    return data;
  }

  public setDownloadStep(
    id: string,
    step: number,
    progress: number
    ) {
    const list = this.getListDownloadStep();
    const index = list.findIndex(x => x.id === id);
    progress = progress > 100 ? 100 : progress;
    if (index === -1) {
      list.push({ id, step, progress});
    } else {
      list[index].step = step;
      list[index].progress = progress;
    }
    this.setListDownloadStep(list);
  }

  public getDownloadStep(id: string) {
    const list = this.getListDownloadStep();
    const index = list.findIndex(x => x.id === id);
    return (index === -1) ? null : list[index];
  }

  public removeDownloadStep(id: string) {
    const list = this.getListDownloadStep();
    const index = list.findIndex(x => x.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      this.setListDownloadStep(list);
    }
  }

  public removeDownloadStepByChartEntitlementId(id: string) {
    const listDownloadStep = this.getListDownloadStep();
    let index = -1;

    if (index === -1) {
      index = listDownloadStep.findIndex(x => x.id === id);
    }

    if (index !== -1) {
      listDownloadStep.splice(index, 1);
      this.setListDownloadStep(listDownloadStep);
    }
  }

  updateChartNotes = (payload: UpdateChartNotesPayload): Observable<any> => {
    return this.http
      .post({ url: endpoints.updateChartNotes, body: payload })
      .pipe(
        mergeMap(this.handleProcessChartResponse),
        catchError((error) => throwError(error))
      );
  }

  private handleProcessChartResponse = (response: any): Observable<any> => {
    return of(response);
  }
}
