import { State } from '@app/core/features';
import {
  MyChartItemChartData,
  MyChartItemBaseSelectorData,
  MyChartItemState,
  ChartInformation,
  MigrationOption
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';
import { areaOfInterestType } from '@app/constants';
import { createSelector, select } from '@ngrx/store';

export const getLocalState = (state: State): MyChartItemState => state.myCharts.chartItem;

export const getDataByEntitlementId = (chartEntitlementId: string, aoiType: areaOfInterestType) => createSelector(
  getLocalState,
  (state: MyChartItemState): MyChartItemChartData[] => {
    switch (aoiType) {
      case areaOfInterestType.CHART_DATA:
        return state.chartData.data[chartEntitlementId];
      case areaOfInterestType.POI:
        return state.chartPoints.data[chartEntitlementId];
      case areaOfInterestType.AERIAL_PHOTOS:
        return state.chartAerials.data[chartEntitlementId];
      default:
        return [];
    }
  }
);

export const getChartItemsByEntitlementIdAndType = (chartEntitlementId: string, aoiType: areaOfInterestType) => createSelector(
  getLocalState,
  (state: MyChartItemState): MyChartItemBaseSelectorData[] => {
    if (state.items[chartEntitlementId]) {
      const items = state.items[chartEntitlementId]
        .filter(item => item.areaOfInterestType === aoiType);
      return items.length ? items : null;
    } else {
      return null;
    }
  }
);

export const getEntireChartDataCapacity = (chartEntitlementId: string, chartSizeKb: number) => createSelector(
  getLocalState,
  (state: MyChartItemState): number => {
    const chart = state.items[chartEntitlementId]
      ? state.items[chartEntitlementId].filter(item => item.areaOfInterestType === areaOfInterestType.CHART_DATA)
      : [];

    return (chart.length === 1 && !chart[0].polygon) ? chartSizeKb : 0;
  }
);

export const getChartIds = (chartEntitlementId: string, aoiType: areaOfInterestType) => createSelector(
  getLocalState,
  (state: MyChartItemState): string[] => {
    return state.items[chartEntitlementId]
      ? state.items[chartEntitlementId]
          .filter(item => item.areaOfInterestType === aoiType)
          .map(({ id }) => id)
      : [];
  }
);

export const getChartMigrationOptions = () => createSelector(
  getLocalState,
  (state: MyChartItemState): MigrationOption[] => {
    return state.migrationOptions.data
  }
);

export const isMigrationOptionsFetching = () => createSelector(
  getLocalState,
  (state: MyChartItemState): boolean => {
    return state.migrationOptions.isFetching
  }
);

export const getChartInformation = () => createSelector(
  getLocalState,
  (state: MyChartItemState): ChartInformation => {
    return state.information
      ? state.information
      : {} as ChartInformation;
  }
);

export const selectors = {
  getDataByEntitlementId: (chartEntitlementId: string, aoiType: areaOfInterestType) =>
    select(getDataByEntitlementId(chartEntitlementId, aoiType)),

  getChartItemsByEntitlementIdAndType: (chartEntitlementId: string, aoiType: areaOfInterestType) =>
    select(getChartItemsByEntitlementIdAndType(chartEntitlementId, aoiType)),

  getEntireChartDataCapacity: (chartEntitlementId: string, chartSizeKb: number) =>
    select(getEntireChartDataCapacity(chartEntitlementId, chartSizeKb)),

  getChartIds: (chartEntitlementId: string, aoiType: areaOfInterestType) =>
    select(getChartIds(chartEntitlementId, aoiType)),

  getChartInformation: () => select(getChartInformation()),

  getChartMigrationOptions: () => select(getChartMigrationOptions()),

  isMigrationOptionsFetching: () => select(isMigrationOptionsFetching())
};
