import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/operators';
import {
  ChartsStoreResponse,
  ProductTranslationsResponseModel,
  SaveLanguageModel,
} from '@app/core/features/charts-store/charts-store.models';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ADD_TO_CART } from '@app/constants/add-to-cart';
import {
  CurrentGroupScrollPositionModel
} from '@app/core/features/charts-manager/my-charts/my-charts-list/my-charts-list.models';
import { environment } from '@env/index';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyUnit, endpoints, language } from '@app/constants';
import { v4 as uuidv4 } from 'uuid';
import { getRandomNumber } from '@app/utils';
import { MyChartsListService } from '@app/core/features/charts-manager/my-charts/my-charts-list/my-charts-list.service';
import polylabel from 'polylabel';
import { HttpService } from '@app/core/services/http';

@Injectable({
  providedIn: 'root'
})
export class ChartsStoreService {
  public checkEditListItemOnCart = new Subject<number>();
  public hidePopoverMenuSubject = new Subject<number>();
  public checkChangeLanguage = new Subject<number>();
  public numberOfChartInVoucherRedeem = new Subject<number>();
  config = environment;
  private language = language;
  private currencyUnit = CurrencyUnit;

  constructor(
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private myChartListService: MyChartsListService,
    private httpService: HttpService,
  ) { }

  public getData = ({ lang = 'en' }): Observable<ChartsStoreResponse> => {
    return this.httpService.get({ url: endpoints.getProduct + endpoints.getAllProductTranslations + lang }).pipe(
      retry(3),
      mergeMap((listResponse: ProductTranslationsResponseModel) => this.handleResponse(listResponse)),
      catchError(error => {
        this.translateService.get('LIST_CHART_STORE.ERROR_MESSAGE.GET_DATA_FAILED').subscribe((value) => {
          this.toastrService.error(value);
        });
        return throwError(error);
      })
    );
  }

  public saveLanguage = (payload: SaveLanguageModel): Observable<object> => {
    return this.httpService
      .post({ url: endpoints.saveLanguage, body: payload })
      .pipe(
        mergeMap(this.handleSaveLanguageResponse),
        catchError(err => {
          this.toastrService.error(err.error.Message);
          return throwError(err);
        })
      );
  }

  private handleSaveLanguageResponse(response): Observable<object> {
    return of(response);
  }

  private handleResponse(listResponse: ProductTranslationsResponseModel): Observable<ChartsStoreResponse> {
    const data = {
      products: listResponse.productTranslations,
      marketing: listResponse.productMarketing
    } as ChartsStoreResponse;

    return of(data);
  }

  public setUserId(id) {
    this.cookieService.set(ADD_TO_CART.SF_XAPI_USER_ID, id, 365, '/', this.config.domainCookies);
  }

  public getUserId() {
    return this.cookieService.get(ADD_TO_CART.SF_XAPI_USER_ID);
  }

  public resetCartData() {
    this.setUserId(uuidv4());
    this.setListFreeCart([]);
    this.setListAddedCart([]);
  }

  public setListFreeCart(list: any[]) {
    const value = JSON.stringify(list);
    this.cookieService.set(ADD_TO_CART.LIST_FREE_CART, value, 365, '/');
  }

  public setListAddedCart(list: any[]) {
    const value = JSON.stringify(list);
    this.cookieService.set(ADD_TO_CART.LATEST_PRODUCT_ADDED, value, 365, '/');
  }

  public getFreeCart(): any[] {
    const value = this.cookieService.get(ADD_TO_CART.LIST_FREE_CART);
    const data = value === '' ? [] : JSON.parse(value);
    return data;
  }

  public addFreeCart(data: any) {
    let list = this.getFreeCart();
    if (list.length > 0) {
      list = list.filter(row => row.voucherCode === data.voucherCode);
    }
    list.push(data);
    this.setListFreeCart(list);
  }

  public removeFreeCart(sku: string) {
    const list = this.getFreeCart();
    const index = list.findIndex(row => row.sku === sku);
    if (index !== -1) {
      list.splice(index, 1);
      this.setListFreeCart(list);
    }
  }

  public setAddedProduct(userId: string, sku: string, centerPoint: number[]) {
    // Get the cookie of the latest product
    try {
      const latestAdded = JSON.parse(this.cookieService.get(ADD_TO_CART.LATEST_PRODUCT_ADDED));
      const itemIndex = latestAdded.findIndex((x: any) => x.userId === userId);

      if (itemIndex < 0) {
        latestAdded.push({
          userId,
          product: [{ sku, centerPoint }]
        });
      } else {
        latestAdded[itemIndex].product.push({ sku, centerPoint });
      }

      this.cookieService.set(ADD_TO_CART.LATEST_PRODUCT_ADDED, JSON.stringify(latestAdded), 365, '/');
    } catch (ex) {
      // Set to cookie the latest product name added to cart
      this.cookieService.set(ADD_TO_CART.LATEST_PRODUCT_ADDED, JSON.stringify([{
        userId,
        product: [{ sku, centerPoint }]
      }]), 365, '/');
    }
  }

  public removeAddedProduct(userId: string, sku: string) {
    try {
      const latestAdded = JSON.parse(this.cookieService.get(ADD_TO_CART.LATEST_PRODUCT_ADDED));
      const itemIndex = latestAdded.findIndex((x: any) => x.userId === userId);

      if (itemIndex >= 0) {
        latestAdded[itemIndex].product = latestAdded[itemIndex].product.filter(item => item.sku !== sku);
        this.cookieService.set(ADD_TO_CART.LATEST_PRODUCT_ADDED, JSON.stringify(latestAdded), 365, '/');
      }
    } catch (ex) { }
  }

  public setCurrentGroupScrollPosition(data: CurrentGroupScrollPositionModel) {
    const value = JSON.stringify(data);
    this.cookieService.set(ADD_TO_CART.CURRENT_GROUP_SCROLL_POSITION, value, 365, '/');
  }

  public getCurrentGroupScrollPosition(): CurrentGroupScrollPositionModel {
    const value = this.cookieService.get(ADD_TO_CART.CURRENT_GROUP_SCROLL_POSITION);
    const data = value === '' ? null : JSON.parse(value);
    return data;
  }

  public setHidemycharts(value) {
    this.cookieService.set(ADD_TO_CART.HIDE_MY_CHART, value.toString(), 365, '/');
  }

  public getHidemycharts() {
    return this.cookieService.get(ADD_TO_CART.HIDE_MY_CHART) === 'true';
  }

  public hidePopoverMenu() {
    this.hidePopoverMenuSubject.next(getRandomNumber());
  }

  public getCurrencyUnit(lang: string) {
    return this.language.find(row => row.code === lang).unit;
  }

  public getCurrencyUnitFromData(lang: string) {
    return this.currencyUnit.find(row => row.code === lang).unit;
  }

  public getZuoraLanguageName(lang: string) {
    return this.language.find(row => row.code === lang).zuoraName;
  }

  public getZuoraLanguageCode(lang: string) {
    return this.language.find(row => row.code === lang).code;
  }

  public getCenterPointOfPolygon = (geometry: any) => {
    const polygon = geometry.type === 'Polygon'
      ? geometry.coordinates
      : geometry.coordinates[0];

    return polylabel(polygon, 1.0);
  }
}
