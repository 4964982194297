import { areaOfInterestType } from '@app/constants';
import { BehaviorSubject } from 'rxjs';
import { CapacityModel } from '@app/core/features/charts-manager/my-charts';
import { removeLighthouseText } from '../my-charts-list';

export interface MyChartItemCapacity {
  capacity: BehaviorSubject<CapacityModel[]>;
  areaOfInterestType: areaOfInterestType;
}

export interface MyChartItemBaseSelectorData {
  id: string;
  polygon: PolygonData;
  sizeKb?: number;
  packageStatus: number;
  errorCode: string;
  aerialsDetail?: number;
}

export interface MyChartItemChartData extends MyChartItemBaseSelectorData {
  areaOfInterestType: areaOfInterestType;
}

export interface MyChartItem extends MyChartItemBaseSelectorData {
  areaOfInterestType: areaOfInterestType;
  lastDownloadedProductVersion: string;
}

export interface PolygonData {
  feature: Geometry;
}

export interface Geometry {
  type: string;
  bbox: [number, number, number, number];
  geometry: Polygon;
  properties?: PropertyData;
}

export interface PropertyData {
  spatialId: string;
}

export interface Polygon {
  type: 'Polygon';
  coordinates: [];
}

export interface MyChartItemField {
  [chartEntitlementId: string]: MyChartItemChartData[];
}

export interface MyChartItems {
  [entitlementId: string]: MyChartItem[];
}

export interface DeleteChartItemsPayload {
  entitlementId: string;
  aoiType: areaOfInterestType;
}

export interface DeleteChartItemsResponse extends DeleteChartItemsPayload {
  ids: string[];
  response: any;
}

export interface DeleteChartItemAllPayload {
  ids: string[];
}

export interface DeleteChartItemAllMeta {
  chartEntitlementId: string;
  aoiType: areaOfInterestType;
}

export interface MyChartItemPayload {
  chartEntitlementId: string;
  aoiType?: areaOfInterestType;
}
export interface ChartEntitlementPayload {
  chartEntitlementId: string;
}

export interface DeleteChartItemOnePayload {
  id: string;
}

export interface DeleteChartItemOneMeta {
  chart: MyChartItemChartData;
  chartEntitlementId: string;
  aoiType: areaOfInterestType;
}

export interface CreateUpdateMyChartItemField extends MyChartItemChartData {
  entitlementId: string;
}

export interface CreateMyChartItemPayload {
  chartEntitlementId: string;
  saveEntitlementAreaOfInterestRequests: CreateUpdateMyChartItemField[];
}

export interface CreateMyChartItemMeta {
  aoiType: areaOfInterestType;
}

export interface MyChartItemDataState {
  data: MyChartItemField;
  isFetching: boolean;
  error: any;
}

export interface MigrationOptionsState {
  data: MigrationOption[];
  isFetching: boolean;
  error: any;
}

export interface MyChartItemState {
  items: MyChartItems;
  information: ChartInformation;
  chartData: MyChartItemDataState;
  chartAerials: MyChartItemDataState;
  chartPoints: MyChartItemDataState;
  migrationOptions: MigrationOptionsState;
}

export interface CreateEntirePayload {
  chartEntitlementId: string;
  entitlementId: string;
  areaOfInterestType: areaOfInterestType;
  entireArea: boolean;
}

export interface MyChartItemProperty {
  capacity: BehaviorSubject<number>;
  timer: BehaviorSubject<NodeJS.Timer>;
  loading: boolean;
}

export interface ChartInformation {
  chartId: string;
  chartName: string;
  version: string;
  sku: string;
  userDeviceId: string;
  chartLastUpdated: string;
  subscriptionExpiration: string;
}

export const chartInformationResponseConvertSchema = {
  chartId: 'id',
  chartName: ({ baseProductEntitlement }) => removeLighthouseText(baseProductEntitlement.product.name),
  version: ({ baseProductEntitlement }) => baseProductEntitlement.product.version,
  sku: ({ baseProductEntitlement }) => baseProductEntitlement.product.sku,
  userDeviceId: ({ userDevice }) => userDevice ? userDevice.id : '',
  chartLastUpdated: ({ baseProductEntitlement }) => baseProductEntitlement.product.lastUpdatedDateTime ?
    baseProductEntitlement.product.lastUpdatedDateTime : '',
  subscriptionExpiration: ({ baseProductEntitlement }) => baseProductEntitlement.expiresDateTime ?
    baseProductEntitlement.expiresDateTime : ''
};

export interface SubmitImproveChartsResponse {
  message: string;
  success: boolean;
}

export interface MigrationOptionsQuery {
  chartEntitlementIds: string[];
}

export interface MigrationOption {
  sku: string;
  chartName: string;
  isRegion: boolean;
  chartSizeKB: number;
  chartContentsList: any[];
}

export interface MigrationOptionsResponse {
  migrationOptions: MigrationOption[];
}

export interface MigrateMergeChartPayload {
  chartEntitlementIds: string[];
  regionSKU: string;
}

export interface ClearUserDevicePayload {
  chartEntitlementIds: string[];
}

export interface MFDTypePayload {
  userID: string;
  mfdType: number;
}

export interface smallChartDownloadedAcknowledgedPayload {
  chartEntitlementId: string;
  smallChartDownloadedAcknowledged: boolean;
}