import { Observable, of } from 'rxjs';
import { endpoints, excludeProducts } from '@app/constants';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductQuery } from './product.payload';
import { convertObjectValuesToString } from '@app/utils';
import { Product } from './product.models';
import { HttpService } from '@app/core/services/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(
    private httpService: HttpService,
  ) { }

  fetchProductList = (query: ProductQuery): Observable<object> => {
    const convertedQuery = convertObjectValuesToString(query);

    return this.httpService.setParams(convertedQuery).get({ url: endpoints.getProduct })
      .pipe(
        mergeMap(this.handleGetProductsResponse),
        catchError((error) => of(error))
      );
  }

  fetchProductBySku = (sku: string): Observable<Product> => {
    return this.httpService.get({ url: endpoints.getProductBySku + sku })
      .pipe(
        mergeMap(this.handleGetProductResponse),
        catchError((error) => of(error))
      );
  }

  private handleGetProductsResponse(response: Product[]): Observable<Product[]> {
    const data = response
      .filter(x => x.polygon && !excludeProducts.some(y => x.sku.includes(y)))
      .map(z => {
        z.polygon.feature.properties.SKU = z.sku;

        return z;
      });

    return of(data);
  }

  private handleGetProductResponse(response: Product): Observable<Product> {
    return of(response);
  }
}
