export enum areaOfInterestType {
  CHART_DATA = 0,
  POI = 1,
  AERIAL_PHOTOS = 2,
}

export enum accountType {
  User = 0,
  Admin = 1,
  Dealer = 2,
}

export enum userRole {
  Dealer = 6,
  Tester = 12,
  FieldTrialer = 15
}

export enum transferChartType {
  Unassign = 0,
  Pending = 1,
  Success = 2,
}

export enum TransferChartExceptionCodes {
  DuplicateEmail = 0,
  TransferChartExpired = 1,
  InvalidEmailInvitation = 2,
  TransferChartCanceled = 3,
  TransferChartAlreadyAccepted = 4,
}

export enum componentType {
  MyChartItem = 0,
  DownloadCharts = 1
}

export const ChartType = {
  LIGHT_HOUSE_CHART: 0,
  RX6: 1,
  LighthouseRegional: 2,
  Sailfish: 3
};

export const PackageStatus = {
  Processing: 0,
  Successful: 1,
  Failed: 2
};

export const AerialDetail = {
  Low: 0,
  Medium: 1,
  High: 2
};

export const FreeType = {
  NA: 0, // North America
  FREE: 1,  // Free
  TRIAL: 2,
  BUNDLED: 3,
  REGIONAL: 4
};

export const MFDType = {
  Axiom: 0,
  Element: 1
};

export const DownloadChartType = {
  HasLightHouseId: '0',
  HasEntitlementId: '1',
};

export enum VoucherType {
  BundledProduct = 0,
  TwoCountriesFree = 1,
  TrialChart = 2,
  BundledPlusOne = 3,
  Regional = 4
}

export const InitialDownload = {
  Step1: 1,
  Step2: 2,
  Step3: 3,
  Step4: 4,
  Step5: 5,
  Step6: 6,
  Step7: 7
};

export const SubsequentDownload = {
  Step1: 1,
  Step2: 2,
  Step3: 3,
  Step4: 4
};

export const ResubscribeMethod = {
  Resubscribe: 0,
  PurchasePremium: 1,
  PurchaseUpdate: 2
};


export const TitleType = {
  Add: 0,
  All: 1,
  Done: 2,
  Draw: 3,
  OutOfProduct: 4,
  ExceedsSizeLimit: 5
};

export const DateTimeRegion = {
  EU: 0,
  US: 1,
  Other: 2,
};

export const VoucherCountryCode = {
  Australia: 'AUS',
  AustraliaAndNewZealand: 'OCE'
}

export enum cardType {
  NEW_CARD = 0,
  EXISTING_CARD = 1,
}
