import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { MyChartListState } from './my-charts-list.models';
import { actionTypes } from './my-charts-list.actions';

const initialState: MyChartListState = {
    data: [],
    isFetching: false,
    error: null,
    isEmpty: false
};

export const reducer = handleActions({
  [actionTypes.MY_CHART_LIST]: (state: MyChartListState) => {
      return update(state, {
          isFetching: { $set: true },
          error: { $set: null }
      });
  },
  [resolved(actionTypes.MY_CHART_LIST)]: (state: MyChartListState, action: ResolvedAction) => {
      const { payload: { data } } = action;
      return update(state, {
          data: { $set: data },
          isFetching: { $set: false },
          isEmpty: { $set: data.length === 0 ? true : false }
      });
  },
  [rejected(actionTypes.MY_CHART_LIST)]: (state: MyChartListState, action: RejectedAction) => {
      const { payload: { error } } = action;
      return update(state, {
          error: { $set: error },
          isFetching: { $set: false }
      });
  }
}, initialState);
