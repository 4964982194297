import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/shared/models';
import { GetRatePlanListQuery, GetStatusProvisioningRequest, PreviewOrderRequest } from './checkout.models';

export const actionTypes: ObjectWithStringValues = {
  GET_RATE_PLAN_LIST: 'GET_RATE_PLAN_LIST',
  GET_STATUS_PROVISIONING: 'GET_STATUS_PROVISIONING',
  GET_LIST_CURRENCIES_AND_TAXES: 'GET_LIST_CURRENCIES_AND_TAXES',
  GET_PREVIEW_ORDER: 'GET_PREVIEW_ORDER'
};

export class GetRatePlanList implements Action {
  readonly type = actionTypes.GET_RATE_PLAN_LIST;

  constructor(public payload: GetRatePlanListQuery) { }
}

export class GetStatusProvisioning implements Action {
  readonly type = actionTypes.GET_STATUS_PROVISIONING;

  constructor(public payload: GetStatusProvisioningRequest) { }
}

export class GetListCurrenciesAndTaxes implements Action {
  readonly type = actionTypes.GET_LIST_CURRENCIES_AND_TAXES;

  constructor() { }
}

export class GetPreviewOrder implements Action {
  readonly type = actionTypes.GET_PREVIEW_ORDER;

  constructor(public payload: PreviewOrderRequest) { }
}
