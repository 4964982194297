import { Injectable } from '@angular/core';
import { BaseEffects, rejected, RejectedAction, resolved, ResolvedAction } from '@app/core/features/base';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State } from '@app/core/features';
import { Observable, of } from 'rxjs';
import {
  actionTypes,
  CreateChartItem,
  DeleteChartItemAll,
  DeleteChartItemOne,
  DeleteChartItems,
  GetChartItemAction,
  GetChartItemsAction,
  CreateEntire,
  GetChartEntitlementByIdAction,
  GetMigrationOptions,
  MigrateMergeChart,
  ClearUserDevice,
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.actions';
import { MyChartItemService } from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.service';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { selectors } from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.selectors';
import {
  DeleteChartItemsResponse,
  DeleteChartItemsPayload
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';
import { Router } from '@angular/router';
import { paths } from '@app/constants';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MyChartItemEffects extends BaseEffects {
  constructor(
    protected actions: Actions,
    protected store: Store<State>,
    protected myChartItemService: MyChartItemService,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  @Effect()
  getChartEntitlementById: Observable<Action> = this.takeAction<GetChartItemAction>(
    actionTypes.GET_CHART_ENTITLEMENT_BY_ID,
    this.handleGetChartEntitlementByIdResponse
  );

  @Effect()
  getChartItem: Observable<Action> = this.takeAction<GetChartItemAction>(
    actionTypes.GET_CHART_ITEM,
    this.handleGetResponse
  );

  @Effect()
  createChartItem: Observable<Action> = this.takeAction<CreateChartItem>(
    actionTypes.CREATE_CHART_ITEM,
    this.handleCreateChartItem
  );

  @Effect({dispatch: false})
  createChartItemSuccess: Observable<Action> = this.takeActionSilently<ResolvedAction>(
    resolved(actionTypes.CREATE_CHART_ITEM),
    () => this.handleCreateChartSuccess()
  );

  @Effect({dispatch: false})
  createChartItemFailed: Observable<Action> = this.takeActionSilently<RejectedAction>(
    rejected(actionTypes.CREATE_CHART_ITEM),
    (action: RejectedAction) => this.handleCreateChartFailed(action)
  );

  @Effect()
  createEntire: Observable<Action> = this.takeAction<CreateChartItem>(
    actionTypes.CREATE_ENTIRE,
    this.handleCreateEntire
  );

  @Effect({ dispatch: false })
  createEntireSuccess: Observable<Action> = this.takeActionSilently<ResolvedAction>(
    resolved(actionTypes.CREATE_ENTIRE),
    () => this.handleCreateChartSuccess()
  );

  @Effect({ dispatch: false })
  createEntireFailed: Observable<Action> = this.takeActionSilently<RejectedAction>(
    rejected(actionTypes.CREATE_ENTIRE),
    (action: RejectedAction) => this.handleCreateChartFailed(action)
  );

  @Effect()
  deleteChartItemOne: Observable<Action> = this.takeAction<DeleteChartItemOne>(
    actionTypes.DELETE_CHART_ITEM_ONE,
    this.handleDelete
  );

  @Effect()
  deleteChartItemAll: Observable<Action> = this.takeAction<DeleteChartItemAll>(
    actionTypes.DELETE_CHART_ITEM_ALL,
    this.handleDeleteAll
  );

  @Effect()
  getChartItems: Observable<Action> = this.takeAction<GetChartItemsAction>(
    actionTypes.GET_CHART_ITEMS,
    this.handleGetChartItemsResponse
  );

  @Effect()
  getMigrationOptions: Observable<Action> = this.takeAction<GetMigrationOptions>(
    actionTypes.GET_MIGRATION_OPTIONS,
    this.handleGetMigrationOptions
  );

  @Effect()
  migrateMergeChart: Observable<Action> = this.takeAction<MigrateMergeChart>(
    actionTypes.MIGRATE_MERGE_CHART,
    this.handleMigrateMergeChart
  );

  @Effect()
  clearUserDevice: Observable<Action> = this.takeAction<ClearUserDevice>(
    actionTypes.CLEAR_USER_DEVICE,
    this.handleClearUserDevice
  );

  @Effect()
  deleteChartItems: Observable<Action> = this.takeOfType<DeleteChartItems>(actionTypes.DELETE_CHART_ITEMS).pipe(
    mergeMap((action: DeleteChartItems) => (
      selectors.getChartIds(action.payload.entitlementId, action.payload.aoiType)(this.store)
        .pipe(
          take(1),
          map((ids) => ([action, ids]))
        )
    )),
    mergeMap(([action, ids]: [DeleteChartItems, string[]]) => {
      const { type, payload } = action;
      return this.handleDeleteChartItems(type, payload, ids);
    })
  );

  @Effect({ dispatch: false })
  deleteChartItemsSuccess: Observable<Action> = this.takeActionSilently<ResolvedAction>(
    resolved(actionTypes.DELETE_CHART_ITEMS),
    () => {}
  );

  @Effect({ dispatch: false })
  deleteChartItemsFailed: Observable<Action> = this.takeActionSilently<RejectedAction>(
    rejected(actionTypes.DELETE_CHART_ITEMS),
    () => {}
  );

  private handleGetChartEntitlementByIdResponse(action: GetChartEntitlementByIdAction): Observable<ResolvedAction | RejectedAction> {
    const {type, payload} = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.getChartEntitlementById, payload);
  }

  private handleGetResponse(action: GetChartItemAction): Observable<ResolvedAction | RejectedAction> {
    const {type, payload} = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.getChartItem, payload);
  }

  private handleCreateChartItem(action: CreateChartItem): Observable<ResolvedAction | RejectedAction> {
    const {type, payload, meta: { aoiType }} = action;
    const meta = {
      chartEntitlementId: payload.chartEntitlementId,
      aoiType
    };
    return this.myChartItemService.postChartItem(payload).pipe(
      map(response => this.handleCreateSuccessResponse(type, meta, response)),
      catchError((error) => this.handleCreateFailedResponse(type, error))
    );
  }

  private handleCreateEntire(action: CreateEntire): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.myChartItemService.createEntireChartItem(payload).pipe(
      map(response => this.handleCreateSuccessResponse(type, { chartEntitlementId: payload.chartEntitlementId }, response)),
      catchError((error) => this.handleCreateFailedResponse(type, error))
    );
  }

  private handleDelete(action: DeleteChartItemOne): Observable<ResolvedAction | RejectedAction> {
    const {type, payload, meta} = action;
    return this.myChartItemService.deleteOneChartItem(payload).pipe(
      map((response) => this.handleDeleteOneSuccess(response, type, meta)),
      catchError((error) => this.handleDeleteOneFailed(type, error))
    );
  }

  private handleDeleteAll(action: DeleteChartItemAll): Observable<ResolvedAction | RejectedAction> {
    const {type, payload, meta} = action;
    return this.handleCommonApiRequest(type, meta)(this.myChartItemService.deleteAllChartItem, payload);
  }

  private handleCreateSuccessResponse(actionType: string, meta, response): ResolvedAction {
    if (response instanceof HttpErrorResponse) {
      var message = '';
        if(response.error){
          message = response.error.Message;
        } else{
          message = response.message;
        }
      return new RejectedAction(actionType, {error: message});
    }
    return new ResolvedAction(actionType, {data: response}, meta);
  }

  private handleCreateFailedResponse(actionType: string, error?: Error): Observable<RejectedAction> {
    return of(new RejectedAction(actionType, {error: error && error.message}));
  }

  private handleCreateChartSuccess(): void {
    this.router.navigate([paths.CHARTS_MANAGER, paths.MY_CHART], {replaceUrl: false});
  }

  private handleCreateChartFailed(action: RejectedAction): void {
    const {payload: {error}} = action;
    this.translateService
      .get('CHART_MANAGER.AREA_SELECTOR.CHART_DATA.ERROR_MESSAGE.CREATE_FAILED')
      .subscribe(message => this.toastr.error(error, message));
  }

  private handleGetChartItemsResponse(action: GetChartItemAction): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.getChartItems, payload);
  }

  private handleDeleteChartItems(actionType: string, payload: DeleteChartItemsPayload, ids: string[]) {
    return this.myChartItemService.deleteChartItems(ids).pipe(
      map((response) => this.handleDeleteChartItemsSuccessResponse(actionType, response, payload, ids)),
      catchError((error) => this.handleDeleteChartItemsFailedResponse(actionType, error))
    );
  }

  private handleDeleteChartItemsSuccessResponse(
    actionType: string, response: any,
    payload: DeleteChartItemsPayload, ids: string[]
  ): ResolvedAction {
    const data: DeleteChartItemsResponse = {
      entitlementId: payload.entitlementId,
      aoiType: payload.aoiType,
      response,
      ids
    };
    return new ResolvedAction(actionType, { data });
  }

  private handleDeleteChartItemsFailedResponse(actionType: string, error?: Error): Observable<RejectedAction> {
    return of(new RejectedAction(actionType, { error: error && error.message }));
  }

  private handleDeleteOneSuccess(response, actionType, meta): ResolvedAction | RejectedAction {
    if (response instanceof HttpErrorResponse) {
      var message = '';
        if(response.error){
          message = response.error.Message;
        } else{
          message = response.message;
        }
      return new RejectedAction(actionType, {error: {message}});
    }
    return new ResolvedAction(actionType, {data: meta});
  }

  private handleDeleteOneFailed(actionType: string, error: Error): Observable<RejectedAction> {
    return of(new RejectedAction(actionType, {error: error && error.message}));
  }

  private handleGetMigrationOptions(action: GetMigrationOptions): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.getMigrationOptions, payload);
  }

  private handleMigrateMergeChart(action: MigrateMergeChart): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.migrateMergeChart, payload);
  }

  private handleClearUserDevice(action: ClearUserDevice): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type, payload)(this.myChartItemService.clearUserDevice, payload);
  }
}
