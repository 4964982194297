import { State } from '@app/core/features';
import { createSelector, select } from '@ngrx/store';
import { MaintenanceMode, MaintenanceModeState } from './maintenance-mode.models';

const getLocalState = (state: State): MaintenanceModeState => state.maintenanceMode;


const getServerModeData = createSelector(
    getLocalState,
    (state: MaintenanceModeState): MaintenanceMode | undefined => {
        return state.data.find(x => x.isServerAndFlagOn);
    }
);

const getPaymentModeData = createSelector(
    getLocalState,
    (state: MaintenanceModeState): MaintenanceMode | undefined => {
        return state.data.find(x => x.isPaymentAndFlagOn);
    }
);


export const selectors = {
    getServerModeData: select(getServerModeData),
    getPaymentModeData: select(getPaymentModeData)
};