import { Injectable } from '@angular/core';
import { endpoints } from '@app/constants';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { MaintenanceMode } from './maintenance-mode.models';
import { HttpService } from '@app/core/services/http';

@Injectable({
    providedIn: 'root'
})

export class MaintenanceModeServices {

    constructor(
        private http: HttpService
    ) { }

    getListMaintenanceModeFlag = (): Observable<object> => {
        return this.http
            .get({ url: `${endpoints.maintenanceMode}${endpoints.flag}` })
            .pipe(
                mergeMap(this.handleGetListMaintenanceModeAndFlag),
                catchError((error) => of(error)),
            );
    }


    private handleGetListMaintenanceModeAndFlag(response: MaintenanceMode[]): Observable<MaintenanceMode[]> {
        const updatedTimeResponse = response.map(item => {
            if (item.message && item.resolveTime) {
                const date = new Date(item.resolveTime);
                const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    weekday: 'short',
                    day: '2-digit',
                    month: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZoneName: 'short',
                    hour12: false
                }).format(date).replace(',', '');
                item.message = item.message.replace('{{Resolve Time}}', formattedDate);
            }
            return item;
        });
    
        return of(updatedTimeResponse);
    }
}