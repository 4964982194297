import { Observable, of } from 'rxjs';
import { CurrencyUnit, endpoints } from '@app/constants';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { convertObjectValuesToString, pickModelValues } from '@app/utils';
import { HttpService } from '@app/core/services/http';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  GetRatePlanListQuery,
  GetRatePlanListResponse,
  GetStatusProvisioningRequest,
  ProductRatePlan,
  productRatePlanConvertSchema,
  CreateAccountRequest,
  CreateAccountResponse,
  GetStatusProvisioningResponse,
  Currency,
  PreviewOrderRequest,
  PreviewOrderResponse,
  UpdateAccountRequest,
  CompleteProvisioningCallBackRequest
} from './checkout.models';
import { CurrencyUnitModel } from '../../charts-store';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private currencyUnit = CurrencyUnit;

  constructor(
    private httpService: HttpService,
  ) { }

  public getCurrencyPricingFromLanguage(lang: string): CurrencyUnitModel {
    return this.currencyUnit.find(row => row.code === lang);
  }

  getRatePlanList = (query: GetRatePlanListQuery): Observable<object> => {
    const convertedQuery = convertObjectValuesToString(query);

    return this.httpService.setParams(convertedQuery).get({ url: `${endpoints.zuora}${endpoints.getRatePlanList}` })
      .pipe(
        mergeMap(this.handleGetRatePlanListResponse),
        catchError((error) => of(error))
      );
  }

  createOrder = (payload: CreateOrderRequest): Observable<any> => {
    return this.httpService
      .post({ url: `${endpoints.zuora}${endpoints.order}${endpoints.create}`, body: payload })
      .pipe(
        mergeMap(this.handleCreateOrderResponse),
        catchError(error => of(error))
      );
  }

  createAccount = (payload: CreateAccountRequest): Observable<CreateAccountResponse> => {
    return this.httpService
      .post({ url: `${endpoints.zuora}${endpoints.account}${endpoints.create}`, body: payload })
      .pipe(
        mergeMap(this.handleCreateAccountResponse),
        catchError(error => of(error))
      );
  }

  getStatusProvisioning = (payload: GetStatusProvisioningRequest): Observable<GetStatusProvisioningResponse[]> => {
    return this.httpService
      .post({ url: endpoints.zuora + endpoints.getStatusProvisioning, body: payload })
      .pipe(
        mergeMap(this.handleGetStatusProvisioningResponse),
        catchError(error => of(error))
      );
  }

  getListCurrenciesAndTaxes = (): Observable<Currency[]> => {
    return this.httpService
      .get({ url: `${endpoints.zuora}${endpoints.getListCurrenciesAndTaxes}` })
      .pipe(
        mergeMap(this.handleGetListCurrenciesAndTaxesResponse),
        catchError((error) => of(error)),
      )
  }

  getPreviewOrder = (payload: PreviewOrderRequest): Observable<PreviewOrderResponse> => {
    return this.httpService
      .post({ url: endpoints.zuora + endpoints.order + endpoints.preview, body: payload })
      .pipe(
        mergeMap(this.handleGetPreviewOrderResponse),
        catchError((error) => of(error)),
      )
  }

  updateAccount = (payload: UpdateAccountRequest): Observable<object> => {
    return this.httpService
    .post({url: `${endpoints.zuora}${endpoints.account}${endpoints.update}`, body: payload})
    .pipe(
      mergeMap(this.handleUpdateAccount),
      catchError(error => of(error))
    );
  }

  completeProvisioningCallBack = (payload: CompleteProvisioningCallBackRequest): Observable<any> => {
    return this.httpService
      .post({ url: `${endpoints.zuora}${endpoints.provisioning}${endpoints.completeProvisioningCallBack}`, body: payload })
      .pipe(
        mergeMap(this.handleCompleteProvisioningCallBack),
        catchError(error => of(error))
      );
  }

  private handleGetRatePlanListResponse(response: GetRatePlanListResponse): Observable<ProductRatePlan[]> {
    const ratePlanList = [];

    response.productRatePlans.forEach(element => {
      const ratePlan = pickModelValues(element, productRatePlanConvertSchema) as ProductRatePlan;
      ratePlanList.push(ratePlan);
    });

    return of(ratePlanList);
  }

  private handleCreateOrderResponse(response: CreateOrderResponse): Observable<any> {
    return of(response);
  }

  private handleCreateAccountResponse(response: CreateAccountResponse): Observable<CreateAccountResponse> {
    return of(response);
  }

  private handleGetStatusProvisioningResponse(response: GetStatusProvisioningResponse[]): Observable<GetStatusProvisioningResponse[]> {
    return of(response);
  }

  private handleGetListCurrenciesAndTaxesResponse(response: Currency[]): Observable<Currency[]> {
    return of(response);
  }

  private handleGetPreviewOrderResponse(response: PreviewOrderResponse): Observable<PreviewOrderResponse> {
    return of(response);
  }
  
  private handleUpdateAccount(response: object): Observable<object> {
    return of(response);
  }

  private handleCompleteProvisioningCallBack(response: object): Observable<object> {
    return of(response);
  }
}
