import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { Observable } from 'rxjs';
import { State } from '@app/core/features';
import { AuthService } from './auth.service';
import { actionTypes, FetchFeedbackDetail, FetchLoggedInUser } from './auth.actions';

@Injectable()
export class AuthEffects extends BaseEffects {
    constructor(
        protected actions: Actions,
        protected store: Store<State>,
        private authService: AuthService,
    ) {
        super();
    }

    @Effect()
    fetchCurrent: Observable<Action> = this.takeAction<FetchLoggedInUser>(
        actionTypes.FETCH_LOGGED_IN_USER,
        this.handleLoggedInUserFetch
    );

    @Effect()
    fetchFeedbackDetail: Observable<Action> = this.takeAction<FetchLoggedInUser>(
        actionTypes.FETCH_FEEDBACK_DETAIL,
        this.handleFeedbackDetailFetch
    );

    private handleLoggedInUserFetch(action: FetchLoggedInUser): Observable<ResolvedAction | RejectedAction> {
        const { type } = action;
        return this.handleCommonApiRequest(type)(this.authService.fetchLoggedInUser);
    }

    private handleFeedbackDetailFetch(action: FetchFeedbackDetail): Observable<ResolvedAction | RejectedAction> {
        const { type, payload } = action;
        return this.handleCommonApiRequest(type)(this.authService.fetchFeedbackDetail, payload);
    }
}
