import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { State } from '@app/core/features';
import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import {
  actionTypes, GetListCurrenciesAndTaxes, GetPreviewOrder, GetRatePlanList, GetStatusProvisioning
} from './checkout.actions';
import { CheckoutService } from './checkout.service';

@Injectable()
export class CheckoutEffects extends BaseEffects {
  constructor(
    protected actions: Actions,
    protected store: Store<State>,
    private checkoutService: CheckoutService,

  ) {
    super();
  }

  @Effect()
  getRatePlanList: Observable<Action> = this.takeAction<GetRatePlanList>(
    actionTypes.GET_RATE_PLAN_LIST,
    this.handleGetRatePlanList,
  );

  @Effect()
  getStatusProvisioning: Observable<Action> = this.takeAction<GetStatusProvisioning>(
    actionTypes.GET_STATUS_PROVISIONING,
    this.handleGetStatusProvisioning,
  );

  @Effect()
  getListCurrenciesAndTaxes: Observable<Action> = this.takeAction<GetListCurrenciesAndTaxes>(
    actionTypes.GET_LIST_CURRENCIES_AND_TAXES,
    this.handleGetListCurrenciesAndTaxes,
  );

  @Effect()
  getPreviewOrder: Observable<Action> = this.takeAction<GetPreviewOrder>(
    actionTypes.GET_PREVIEW_ORDER,
    this.handleGetPreviewOrder,
  );


  private handleGetRatePlanList(action: GetRatePlanList): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type)(this.checkoutService.getRatePlanList, payload);
  }

  private handleGetStatusProvisioning(action: GetStatusProvisioning): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type)(this.checkoutService.getStatusProvisioning, payload);
  }

  private handleGetListCurrenciesAndTaxes(action: GetListCurrenciesAndTaxes): Observable<ResolvedAction | RejectedAction> {
    const { type } = action;
    return this.handleCommonApiRequest(type)(this.checkoutService.getListCurrenciesAndTaxes);
  }

  private handleGetPreviewOrder(action: GetPreviewOrder): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type)(this.checkoutService.getPreviewOrder, payload);
  }
}
