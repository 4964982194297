import {
  AerialResolution,
  CountryNameModel,
  CurrencyUnitModel,
  LanguageYouTubeVideoIdModel,
  SelectedLanguageModel,
  ZuoraLanguageModel
} from '@app/core/features/charts-store';
import { AerialDetail, DateTimeRegion } from './enum';

export const constants = {
  axiomDeviceString: 'rk3288',
  returnUrl: 'returnUrl',
  defaultChartName: 'lighthouse_chart.rmc'
};

export const sso = {
  resetPasswordErrorCode: 'AADB2C90118',
  userCancelErrorCode: 'AADB2C90091',
  userInteractErrorCode: 'AADB2C90077',
  idToken: 'msal.idtoken',
  clientInfo: 'msal.client.info'
};

export const language: SelectedLanguageModel[] = [
  { id: 1, name: 'Australia', displayName: 'Australia', code: 'au', unit: '$', group: 1, timeRegion: DateTimeRegion.Other, zuoraName: 'English', raymarineFindDealerUrl: 'https://www.raymarine.com/en-au/find-your-local-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/en-au/policies/terms-of-use-policy'},
  { id: 2, name: 'Canada (English)', displayName: 'Canada (English)', code: 'ca', unit: '$', group: 2, timeRegion: DateTimeRegion.EU, zuoraName: 'English', raymarineFindDealerUrl: 'https://www.raymarine.com/en-us/find-your-local-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/en-us/policies/terms-of-use-policy'},
  { id: 3, name: 'Canada (Français)', displayName: 'Canada (Français)', code: 'ca-fr', unit: '$', group: 2, timeRegion: DateTimeRegion.EU, zuoraName: 'French', raymarineFindDealerUrl: 'https://www.raymarine.com/fr-fr/trouver-votre-revendeur-local', termsOfUsePolicyUrl: 'https://www.raymarine.com/fr-fr/policies/terms-of-use-policy'},
  { id: 4, name: 'Danmark', displayName: 'Danmark', code: 'dk', unit: '€', group: 3, timeRegion: DateTimeRegion.EU, zuoraName: 'Danish', raymarineFindDealerUrl: 'https://www.raymarine.com/da-dk/find-din-lokale-forhandler', termsOfUsePolicyUrl: 'https://www.raymarine.com/da-dk/policies/terms-of-use-policy'},
  { id: 5, name: 'Deutschland', displayName: 'Deutschland', code: 'de', unit: '€', group: 4, timeRegion: DateTimeRegion.EU, zuoraName: 'German', raymarineFindDealerUrl: 'https://www.raymarine.com/de-de/finden-sie-ihren-nachstgelegenen-handler', termsOfUsePolicyUrl: 'https://www.raymarine.com/de-de/policies/terms-of-use-policy'},
  { id: 6, name: 'España', displayName: 'España', code: 'es', unit: '€', group: 5, timeRegion: DateTimeRegion.EU, zuoraName: 'Spanish', raymarineFindDealerUrl: 'https://www.raymarine.com/es-es/encuentre-su-distribuidor-local', termsOfUsePolicyUrl: 'https://www.raymarine.com/es-es/policies/terms-of-use-policy'},
  { id: 7, name: 'Europe (English)', displayName: 'EU (English)', code: 'eu', unit: '€', group: 6, timeRegion: DateTimeRegion.EU, zuoraName: 'English', raymarineFindDealerUrl: 'https://www.raymarine.com/en-gb/find-your-local-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/en-gb/policies/terms-of-use-policy'},
  { id: 8, name: 'France', displayName: 'France', code: 'fr', unit: '€', group: 7, timeRegion: DateTimeRegion.EU, zuoraName: 'French', raymarineFindDealerUrl: 'https://www.raymarine.com/fr-fr/trouver-votre-revendeur-local', termsOfUsePolicyUrl: 'https://www.raymarine.com/fr-fr/policies/terms-of-use-policy'},
  { id: 9, name: 'Italia', displayName: 'Italia', code: 'it', unit: '€', group: 8, timeRegion: DateTimeRegion.EU, zuoraName: 'Italian', raymarineFindDealerUrl: 'https://www.raymarine.com/it-it/cerca-il-nostro-centro-autorizzato-piu-vicino', termsOfUsePolicyUrl: 'https://www.raymarine.com/it-it/policies/terms-of-use-policy'},
  { id: 10, name: 'Nederland', displayName: 'Nederland', code: 'nl', unit: '€', group: 9, timeRegion: DateTimeRegion.EU, zuoraName: 'Dutch', raymarineFindDealerUrl: 'https://www.raymarine.com/nl-nl/vind-uw-plaatselijke-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/nl-nl/policies/terms-of-use-policy'},
  { id: 11, name: 'Norge', displayName: 'Norge', code: 'no', unit: '€', group: 10, timeRegion: DateTimeRegion.EU, zuoraName: 'Norwegian', raymarineFindDealerUrl: 'https://www.raymarine.com/no-no/finn-din-lokale-forhandler', termsOfUsePolicyUrl: 'https://www.raymarine.com/no-no/policies/terms-of-use-policy'},
  { id: 12, name: 'Suomi', displayName: 'Suomi', code: 'fi', unit: '€', group: 11, timeRegion: DateTimeRegion.EU, zuoraName: 'Finnish', raymarineFindDealerUrl: 'https://www.raymarine.com/fi-fi/etsi-paikallinen-jalleenmyyja', termsOfUsePolicyUrl: 'https://www.raymarine.com/fi-fi/policies/terms-of-use-policy'},
  { id: 13, name: 'Sverige', displayName: 'Sverige', code: 'se', unit: '€', group: 12, timeRegion: DateTimeRegion.EU, zuoraName: 'Swedish', raymarineFindDealerUrl: 'https://www.raymarine.com/sv-se/hitta-din-lokala-aterforsaljare', termsOfUsePolicyUrl: 'https://www.raymarine.com/sv-se/policies/terms-of-use-policy'},
  { id: 14, name: 'United Kingdom', displayName: 'United Kingdom', code: 'uk', unit: '£', group: 13, timeRegion: DateTimeRegion.EU, zuoraName: 'English', raymarineFindDealerUrl: 'https://www.raymarine.com/en-gb/find-your-local-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/en-gb/policies/terms-of-use-policy'},
  { id: 15, name: 'United States', displayName: 'United States', code: 'us', unit: '$', group: 14, timeRegion: DateTimeRegion.US, zuoraName: 'English', raymarineFindDealerUrl: 'https://www.raymarine.com/en-us/find-your-local-dealer', termsOfUsePolicyUrl: 'https://www.raymarine.com/en-us/policies/terms-of-use-policy'},
];

export const aerialResolution: AerialResolution[] = [
  { detailLevel: AerialDetail.Low, limitArea: 10000000000 },
  { detailLevel: AerialDetail.Medium, limitArea: 5000000000 },
  { detailLevel: AerialDetail.High, limitArea: 1250000000 },
];

export const HowtoType = {
  FIRST_DRAW: 'FIRST_DRAW',
  FIRST_REACH_BOX_LIMIT: 'FIRST_REACH_BOX_LIMIT',
};

export const improveChartsField = {
  userId: 'userId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  countryCode: 'countryCode',
  telephone: 'telephone',
  address: 'address',
  country: 'country',
  isSaveDetail: 'isSaveDetail',
  isAllowContact: 'isAllowContact',
  dateDetected: 'dateDetected',
  chartId: 'chartId',
  chartLastUpdated: 'chartLastUpdated',
  subscriptionExpiration: 'subscriptionExpiration',
  version: 'version',
  sku: 'sku',
  latitude: 'latitude',
  longitude: 'longitude',
  description: 'description',
  userDeviceId: 'userDeviceId',
  attachments: 'attachments',
};

export const UsLanguages = ['us', 'au'];

export const UsLanguageCode = 'us';

export const DefaultLanguageCode = 'eu';

export const EuroLanguageCode = 'eu';

export const ListLanguageCode = ['au', 'ca-fr', 'ca', 'de', 'dk', 'es', 'eu', 'fi', 'fr', 'it', 'nl', 'no', 'se', 'uk', 'us'];

export const EuroLanguages = ['dk', 'fi', 'nl', 'no', 'se'];

export const excludeProducts = ['EUO', 'TEST', 'FAIL'];

export const languageSupportYouTubeLinkIds: LanguageYouTubeVideoIdModel[] = [
  { languageCode: 'au', videoId: 'wUODVplvlW0' },
  { languageCode: 'ca', videoId: 'wUODVplvlW0' },
  { languageCode: 'ca-fr', videoId: 'uId2Bx0kijk' },
  { languageCode: 'dk', videoId: 'Y_etiOcRhyQ' },
  { languageCode: 'de', videoId: '0W0z6Wp6s5M' },
  { languageCode: 'es', videoId: 'wUODVplvlW0' },
  { languageCode: 'eu', videoId: 'wUODVplvlW0' },
  { languageCode: 'fr', videoId: 'uId2Bx0kijk' },
  { languageCode: 'it', videoId: '6skkYCsDmEQ' },
  { languageCode: 'nl', videoId: 'wUODVplvlW0' },
  { languageCode: 'no', videoId: 'PWdRXNEKaSo' },
  { languageCode: 'fi', videoId: 'Drg3A6iLhl0' },
  { languageCode: 'se', videoId: '9Ts3wYXAMiw' },
  { languageCode: 'uk', videoId: 'wUODVplvlW0' },
  { languageCode: 'us', videoId: 'wUODVplvlW0' },
];

export const CountryCodeConst: CountryNameModel[] = [
  { countryName: 'Australia', code: 'au' },
  { countryName: 'Canada', code: 'ca' },
  { countryName: 'Denmark', code: 'dk' },
  { countryName: 'Germany', code: 'de' },
  { countryName: 'Spain', code: 'es' },
  { countryName: 'France', code: 'fr' },
  { countryName: 'Italy', code: 'it' },
  { countryName: 'Netherlands', code: 'nl' },
  { countryName: 'Norway', code: 'no' },
  { countryName: 'Finland', code: 'fi' },
  { countryName: 'Sweden', code: 'se' },
  { countryName: 'United Kingdom', code: 'uk' },
  { countryName: 'United States of America', code: 'us' },
  { countryName: 'United States Minor Outlying Islands', code: 'us' },
];

export const CurrencyUnit: CurrencyUnitModel[] = [
  { code: 'au', unit: '$', currency: 'AUD' },
  { code: 'ca', unit: '$', currency: 'CAD' },
  { code: 'ca-fr', unit: '$', currency: 'USD' },
  { code: 'dk', unit: '€', currency: 'DKK' },
  { code: 'de', unit: '€', currency: 'USD' },
  { code: 'es', unit: '€', currency: 'USD' },
  { code: 'eu', unit: '€', currency: 'EUR' },
  { code: 'fr', unit: '€', currency: 'USD' },
  { code: 'it', unit: '€', currency: 'USD' },
  { code: 'nl', unit: '€', currency: 'USD' },
  { code: 'no', unit: '€', currency: 'NOK' },
  { code: 'fi', unit: '€', currency: 'USD' },
  { code: 'se', unit: '€', currency: 'SEK' },
  { code: 'uk', unit: '£', currency: 'GBP' },
  { code: 'us', unit: '$', currency: 'USD' },
];

export enum RibbonType {
  Local = 1,
  Dev = 2,
  Stage = 3,
  Preprod = 4,
  Prod = 0,
}

export const SkuProductForTwoCountriesFree = ['LHC-NEU-001', 'LHC-MED-001', 'LHC-WEU-001']

export const TaxInclude = 'TaxInclusive';

export const ZuoraConfig = {
  source: 'Raymarine',
  flirProductId: 'LHC-NAM-P01',
  segment: 'Raymarine'
}

export const StatusSubscription = {
  EXPIRED: 'Expired on:',
  EXPIRES: 'Expires on:',
}

export const defaultCurrencyAndUnit = {
  currency: 'USD',
  unit: '$',
  name: 'United States dollar'
}

export const zuoraCodeConst: ZuoraLanguageModel[] = [
  { countryName: 'Australia', raymarineCode: 'au', zuoraCode: 'en_AU' },
  { countryName: 'Canada (English)', raymarineCode: 'ca', zuoraCode: 'en_CA' },
  { countryName: 'Canada (Français)', raymarineCode: 'ca-fr', zuoraCode: 'fr_CA' },
  { countryName: 'Denmark', raymarineCode: 'dk', zuoraCode: 'da' },
  { countryName: 'Germany', raymarineCode: 'de', zuoraCode: 'de' },
  { countryName: 'Spain', raymarineCode: 'es', zuoraCode: 'es' },
  { countryName: 'Europe (English)', raymarineCode: 'eu', zuoraCode: 'en' },
  { countryName: 'France', raymarineCode: 'fr', zuoraCode: 'fr' },
  { countryName: 'Italy', raymarineCode: 'it', zuoraCode: 'it' },
  { countryName: 'Netherlands', raymarineCode: 'nl', zuoraCode: 'nl' },
  { countryName: 'Norway', raymarineCode: 'no', zuoraCode: 'no' },
  { countryName: 'Finland', raymarineCode: 'fi', zuoraCode: 'fi' },
  { countryName: 'Sweden', raymarineCode: 'se', zuoraCode: 'sv' },
  { countryName: 'United Kingdom', raymarineCode: 'uk', zuoraCode: 'en_GB' },
  { countryName: 'United States of America', raymarineCode: 'us', zuoraCode: 'en' }
];

export const countriesNotMapping: CountryNameModel[] = [
  {
    countryName: 'Aland Islands',
    code: 'AX'
  },
  {
    countryName: 'Bolivia (Plurinational State of)',
    code: 'BO'
  },
  {
    countryName: 'Brasil',
    code: 'BR'
  },
  {
    countryName: 'Cabo Verde',
    code: 'CV'
  },
  {
    countryName: 'Congo (Democratic Republic of the)',
    code: 'CD'
  },
  {
    countryName: 'Congo',
    code: 'CG'
  },
  {
    countryName: 'Cote d\'Ivoire',
    code: 'CI'
  },
  {
    countryName: 'Curacao',
    code: 'CW'
  },
  {
    countryName: 'Czechia',
    code: 'CZ'
  },
  {
    countryName: 'Falkland Islands',
    code: 'FK'
  },
  {
    countryName: 'French Southern Territories',
    code: 'TF'
  },
  {
    countryName: 'Holy See',
    code: 'VA'
  },
  {
    countryName: 'Iran (Islamic Republic of)',
    code: 'IR'
  },
  {
    countryName: 'Korea (the Republic of)',
    code: 'KR'
  },
  {
    countryName: 'Lao People\'s Democratic Republic',
    code: 'LA'
  },
  {
    countryName: 'Macedonia (the former Yugoslav Republic of)',
    code: 'MK'
  },
  {
    countryName: 'Micronesia (Federated States of)',
    code: 'FM'
  },
  {
    countryName: 'Moldova (the Republic of)',
    code: 'MD'
  },
  {
    countryName: 'Korea (the Democratic People\'s Republic of)',
    code: 'KP'
  },
  {
    countryName: 'Reunion',
    code: 'RE'
  },
  {
    countryName: 'Saint Barthelemy',
    code: 'BL'
  },
  {
    countryName: 'Saint Martin (French part)',
    code: 'MF'
  },
  {
    countryName: 'South Georgia and the South Sandwich Islands',
    code: 'GS'
  },
  {
    countryName: 'United States of America',
    code: 'US'
  },
  {
    countryName: 'Venezuela (Bolivarian Republic of)',
    code: 'VE'
  },
  {
    countryName: 'Viet Nam',
    code: 'VN'
  },
  {
    countryName: 'Virgin Islands (British)',
    code: 'VG'
  },
  {
    countryName: 'Virgin Islands (U.S.)',
    code: 'VI'
  }
];

export const fileNameType = {
  pdf: '.pdf'
}

export const SmallChartDownloadedAcknowledgedConfig = {
  axiomLighthouseVersionOrHigher: '4.8',
  elementSoftwareVersionOrHigher: '3.20'
}

export const raymarineUrlForNAMChart = 'https://www.raymarine.com/en-us/our-products/nautical-charts/lighthouse-charts/north-america';