import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { resolved, rejected, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { AccountZuoraState } from './account-zuora.models';
import { actionTypes } from './account-zuora.actions';
import { ZuoraCurrencies } from '@app/constants';

const initialState: AccountZuoraState = {
    accountSummary: {},
    isFetching: false,
    error: null,
    listCreditCardsSummary: {},
    isListCreditCardsLoading: false,
    listZuoraAccount: [],
    isListZuoraAccountFetching: false,
    isListZuoraAccountError: false
};

export const reducer = handleActions({
    [actionTypes.GET_AN_ACCOUNT_SUMMARY]: (state: AccountZuoraState) => {
      return update(state, {
        isFetching: { $set: true },
      });
    },
    [resolved(actionTypes.GET_AN_ACCOUNT_SUMMARY)]: (state: AccountZuoraState, action: ResolvedAction) => {
      const { payload: { data }, meta: { currency} } = action;

      return update(state, {
        accountSummary: { 
          [currency]: { $set: data }
         },
        isFetching: { $set: false },
        error: { $set: null },
      });
    },
    [rejected(actionTypes.GET_AN_ACCOUNT_SUMMARY)]: (state: AccountZuoraState, action: RejectedAction) => {
      const { payload: { error } } = action;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false }
      });
    },
    [actionTypes.GET_LIST_CREDIT_CARDS]: (state: AccountZuoraState) => {
      return update(state, {
        isListCreditCardsLoading: { $set: true },
      });
    },
    [resolved(actionTypes.GET_LIST_CREDIT_CARDS)]: (state: AccountZuoraState, action: ResolvedAction) => {
      const { payload: { data }, meta: { currency } } = action;

      return update(state, {
        listCreditCardsSummary: { 
          [currency]: { $set: data }
        },
        isListCreditCardsLoading: { $set: false },
        error: { $set: null },
      });
    },
    [rejected(actionTypes.GET_LIST_CREDIT_CARDS)]: (state: AccountZuoraState, action: RejectedAction) => {
      const { payload: { error } } = action;
      return update(state, {
        error: { $set: error },
        isListCreditCardsLoading: { $set: false }
      });
    },
    [actionTypes.GET_LIST_ZUORA_ACCOUNT]: (state: AccountZuoraState) => {
      return update(state, {
        isListZuoraAccountFetching: { $set: true },
      });
    },
    [resolved(actionTypes.GET_LIST_ZUORA_ACCOUNT)]: (state: AccountZuoraState, action: ResolvedAction) => {
      let { payload: { data } } = action;
      data = data.map(element => ({ 
        ...element,
        unit: ZuoraCurrencies.find(x => x.currency === element.currency).unit,
        name: ZuoraCurrencies.find(x => x.currency === element.currency).name
      }))

      return update(state, {
        listZuoraAccount: { $set: data },
        isListZuoraAccountFetching: { $set: false },
        isListZuoraAccountError: { $set: null },
      });
    },
    [rejected(actionTypes.GET_LIST_ZUORA_ACCOUNT)]: (state: AccountZuoraState, action: RejectedAction) => {
      const { payload: { error } } = action;
      return update(state, {
        isListZuoraAccountError: { $set: error },
        isListZuoraAccountFetching: { $set: false }
      })
    },
    [actionTypes.SET_LOADING_LIST_CARD]: (state: AccountZuoraState) => {
      return update(state, {
        isListCreditCardsLoading: { $set: true },
      });
    },
}, initialState);
