import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/index';

if (environment.production) {
  enableProdMode();
}

const script = document.createElement('script');
script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}"></script>`;
document.head.appendChild(script);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
