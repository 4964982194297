import { CountryCodeConst, DefaultLanguageCode } from '@app/constants';

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  loggedInUserId: string;
  isFirstTimeDraw: boolean;
  isFirstTimeReachBoxLimit: boolean;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  accountType: number;
  roles: number[];
  postalCode: string;
  language: string;
  zuoraAccountId: string;
  accountId: string;
  zuoraPaymentGateway: string;
  iso2: string;
  currency: string;
  unit: string;
  mfdType?: number;
  streetAddress: string;
  streetAddress2: string;
  phoneNumber: string;
}

export const usersResponseConvertSchema = {
  id: 'id',
  email: 'emailAddress',
  firstName: 'firstName',
  lastName: 'lastName',
  fullName: ({ firstName, lastName }) => firstName + ' ' + lastName,
  loggedInUserId: 'id',
  isFirstTimeDraw: 'isFirstTimeDraw',
  isFirstTimeReachBoxLimit: 'isFirstTimeReachBoxLimit',
  city: 'city',
  state: 'state',
  country: 'country',
  countryCode: ({country}) => {
    const value = CountryCodeConst.find(row => row.countryName === country);
    return value ? value.code : DefaultLanguageCode;
  },
  accountType: 'accountType',
  roles: 'roles',
  postalCode: 'postalCode',
  language: 'language',
  zuoraAccountId: 'zuoraAccountId',
  accountId: 'accountId',
  zuoraPaymentGateway: 'zuoraPaymentGateway',
  iso2: 'iso2',
  currency: 'currency',
  unit: 'unit',
  mfdType: 'mfdType',
  streetAddress: 'streetAddress',
  streetAddress2: 'streetAddress2',
  phoneNumber: 'phoneNumber',
};

export interface AuthState {
  data: UserData;
  feedbackDetail: FeedbackDetail;
  isFetching?: boolean;
  hasError?: boolean;
}

export interface FeedbackDetailPayload {
  userId: string;
}

export interface FeedbackDetail {
  hasValue: boolean;
  data: UserFeedbackDetail;
}

export interface UserFeedbackDetail {
  userId: string;
  email: string;
  countryCode: string;
  telephone: string;
  address: string;
  country: string;
}
