export const httpStatuses = {
  UNAUTHORIZED: 401,
};

export const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
};

export const UNAUTHORIZED_HEADER = 'x-meta-unauthorized';

export const AUTHORIZED_HEADER = 'Authorization';
