import { State } from '@app/core/features';
import { createSelector, select } from '@ngrx/store';
import { AccountSummary, AccountZuoraState, CardInformation, ListCreditCardsSummary, ZuoraAccount } from './account-zuora.models';

const getLocalState = (state: State): AccountZuoraState => state.payment.account;

const getAnAccountSummaryByCurrency = (currency: string) => createSelector(
	getLocalState,
	(state: AccountZuoraState): AccountSummary => state.accountSummary[currency]
);

const getDefaultPaymentMethodIdByCurrency = (currency: string) => createSelector(
	getLocalState,
	(state: AccountZuoraState): string => state.accountSummary[currency]?.basicInfo?.defaultPaymentMethod?.id
);

const getListCreditCardsByCurrency = (currency: string) => createSelector(
	getLocalState,
	(state: AccountZuoraState): ListCreditCardsSummary => state.listCreditCardsSummary[currency]
);

const getDefaultCreditCardByCurrency = (currency: string) => createSelector(
	getLocalState,
	(state: AccountZuoraState): CardInformation => {
		if (state.listCreditCardsSummary[currency]?.creditcard) {
			let defaultCard = state.listCreditCardsSummary[currency].creditcard.find(x => x.isDefault);
			return defaultCard;
		}
		else {
			return null;
		}
	}
);

const isListCardsFetching = createSelector(
	getLocalState,
	(state: AccountZuoraState): boolean => state.isListCreditCardsLoading
);

const isAccountSummaryFetching = createSelector(
	getLocalState,
	(state: AccountZuoraState): boolean => state.isFetching
);

const getListZuoraAccount = createSelector(
	getLocalState,
	(state: AccountZuoraState): ZuoraAccount[] => state.listZuoraAccount
);

const isListZuoraAccountFetching = createSelector(
	getLocalState,
	(state: AccountZuoraState): boolean => state.isListZuoraAccountFetching
);

export const selectors = {
	getListCreditCardsByCurrency: (currency: string) => select(getListCreditCardsByCurrency(currency)),
	isListCardsFetching: select(isListCardsFetching),
	getDefaultCreditCardByCurrency: (currency: string) => select(getDefaultCreditCardByCurrency(currency)),
	isAccountSummaryFetching: select(isAccountSummaryFetching),
	getAnAccountSummaryByCurrency: (currency: string) => select(getAnAccountSummaryByCurrency(currency)),
	getDefaultPaymentMethodIdByCurrency: (currency: string) => select(getDefaultPaymentMethodIdByCurrency(currency)),
	getListZuoraAccount: select(getListZuoraAccount),
	isListZuoraAccountFetching: select(isListZuoraAccountFetching)
};
