import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { httpMethods } from '@app/constants';
import { environment } from '@env/index';
import { BaseRequestConfig, RequestConfig } from './http.models';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers: HttpHeaders;
  private params: HttpParams;
  private responseType: 'json' | 'arraybuffer' | 'blob' | 'text';

  constructor(
    private httpClient: HttpClient
  ) {
    this.resetHeadersAndParams();
  }

  get({ url, baseUrl = environment.baseApiUrl }: RequestConfig): Observable<object> {
    return this.request({ url, baseUrl, method: httpMethods.GET });
  }

  post({ url, baseUrl = environment.baseApiUrl, body = null }: RequestConfig): Observable<object> {
    return this.request({ url, baseUrl, body, method: httpMethods.POST });
  }

  postWithFile({ url, baseUrl = environment.baseApiUrl, body = null }): Observable<object> {
    this.setFormDataHeaders();
    return this.request({ url, baseUrl, body, method: httpMethods.POST });
  }

  put({ url, baseUrl = environment.baseApiUrl, body = null }: RequestConfig): Observable<object> {
    return this.request({ url, baseUrl, body, method: httpMethods.PUT });
  }

  remove({ url, baseUrl = environment.baseApiUrl }: RequestConfig): Observable<object> {
    return this.request({ url, baseUrl, method: httpMethods.DELETE });
  }

  patch({ url, baseUrl = environment.baseApiUrl, body = null }: RequestConfig): Observable<object> {
    return this.request({ url, baseUrl, body, method: httpMethods.PATCH });
  }

  setHeader(key: string, value?: any): HttpService {
    this.headers = this.headers.append(key, value);
    return this;
  }

  removeAllHeaders(): HttpService {
    this.headers.keys().forEach((key) => {
      this.headers = this.headers.delete(key);
    });

    return this;
  }

  setParam(key: string, value: string): HttpService {
    this.params = this.params.append(key, value);
    return this;
  }

  setParams(params): HttpService {
    Object.keys(params).forEach(key => {
      if (params[key]) {
        if (params[key] instanceof Array) {
          params[key].forEach(item => {
            this.params = this.params.append(key, item);
          })
        } else {
          this.params = this.params.append(key, params[key]);
        }
      }
    });
    return this;
  }

  setResponseType(responseType: 'json' | 'arraybuffer' | 'blob' | 'text'): HttpService {
    this.responseType = responseType;
    return this;
  }

  private request({ url, baseUrl = environment.baseApiUrl, body = null, method = httpMethods.GET }: BaseRequestConfig): Observable<object> {
    const options = {
      headers: this.headers,
      params: this.params,
      body,
      responseType: this.responseType
    };
    const absoluteUrl = `${baseUrl}${url}`;
    const request = this.httpClient.request(method, absoluteUrl, options);
    this.resetHeadersAndParams();

    return request;
  }

  private resetHeadersAndParams(): void {
    this.headers = new HttpHeaders();
    this.params = new HttpParams();
    this.responseType = undefined;
    this.setDefaultHeaders();
  }

  private setDefaultHeaders(): void {
    this.setHeader('Content-Type', 'application/json');
    this.setHeader('ImpersonateMode', 'true');
  }

  private setFormDataHeaders(): void {
    this.headers = new HttpHeaders();
  }
}
