import * as development from './environment.dev';
import * as stage from './environment.stage';
import * as production from './environment.prod';
import * as local from './environment';
import * as preprod from './environment.preprod';

let config = local;

switch (window.location.hostname) {
  case 'preprod-chartstore.fishidy.com':
  case 'preprod-chartstore.raymarine.com':
    config = preprod;
    break;
  case 'chartstore.raymarine.com':
  case 'chartmanager.raymarine.com':
    config = production;
    break;
  case 'stage-chartmanager.fishidy.com':
  case 'stage-chartstore.fishidy.com':
  case 'stage-chartstore.raymarine.com':
  case 'stage-chartmanager.raymarine.com':
    config = stage;
    break;
  case 'dev-chartmanager.fishidy.com':
  case 'dev-chartstore.fishidy.com':
  case 'dev-chartstore.raymarine.com':
  case 'dev-chartmanager.raymarine.com':
  case 'test-chartstore.raymarine.com':
    config = development;
    break;
}

export const environment = config.environment;
