import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppTranslation } from '@app/app.translation';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from './shared/models/globals';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { registerLocaleData } from '@angular/common';
import localeAu from '@angular/common/locales/en-AU';
import localeCa from '@angular/common/locales/en-CA';
import localeCaFr from '@angular/common/locales/ca-FR';
import localeDk from '@angular/common/locales/en-DK';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeNo from '@angular/common/locales/nb';
import localeFi from '@angular/common/locales/sv-FI';
import localeSe from '@angular/common/locales/sv';
import localeUk from '@angular/common/locales/en-GB';
import localeUs from '@angular/common/locales/en-US-POSIX';
import { DefaultLanguageCode, endpoints, paths } from './constants';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { environment } from '@env/index';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

registerLocaleData(localeAu, 'au');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeCaFr, 'ca-fr');
registerLocaleData(localeDk, 'dk');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEu, 'eu');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeNo, 'no');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeSe, 'se');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeUs, 'us');

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
        clientId: environment.ssoClientid,
        authority: `${environment.ssoBaseUrl}/${environment.ssoSignUpSignIn}`,
        redirectUri: `${window.location.origin}/${paths.SIGN_IN}`,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
        knownAuthorities: [environment.ssoBaseUrl]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,
      },
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 30000,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(`${environment.baseApiUrl}${endpoints.getProduct}`, null);
  protectedResourceMap.set(`${environment.baseApiUrl}${endpoints.maintenanceMode}${endpoints.flag}`, null);
  protectedResourceMap.set(environment.baseApiUrl, [environment.ssoClientid]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: {
      extraQueryParameters: { branding: 'raymarine' }
    }
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid', environment.ssoClientid, 'offline_access'],
      extraQueryParameters: { branding: 'raymarine' }
    }
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true
    }),
    CoreModule,
    TranslateModule.forRoot(),
    AppTranslation,
    MsalModule
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: DefaultLanguageCode },
    { provide: MAT_DATE_LOCALE, useValue: 'eu' },
    CookieService,
    Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
