import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BaseEffects, ResolvedAction, RejectedAction} from '../base';
import { State } from '@app/core/features';
import { SubscriptionService } from './plan-subscription.service'
import { FetchSubscriptionsAction, UpdateSubscription } from './plan-subscription.action';
import { Observable } from 'rxjs';
import { actionTypes } from './plan-subscription.action';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SubscriptionEffect extends BaseEffects {
    constructor (
        protected actions: Actions,
        protected store: Store<State>,
        private subscriptionService: SubscriptionService
    ) {
        super();
    }

    @Effect()
    fetchSubscription: Observable<Action> = this.takeAction<FetchSubscriptionsAction>(
        actionTypes.FETCH_SUBSCRIPTION,
        this.handleGetSubscription
    )

    @Effect()
    updateSubscription: Observable<Action> = this.takeAction<UpdateSubscription>(
        actionTypes.UPDATE_SUBSCRIPTION,
        this.handleUpdateSubscription
    )

    private handleGetSubscription(action: FetchSubscriptionsAction): Observable<ResolvedAction | RejectedAction> {
        const { type, payload, meta } = action;
        return this.handleCommonApiRequest(type, meta)(this.subscriptionService.getDataSubscription, payload);
    }

    private handleUpdateSubscription(action: UpdateSubscription): Observable<ResolvedAction | RejectedAction> {
        const { type, payload } = action;
        return this.subscriptionService.updateSubscription(payload).pipe(
            map(response => this.handleUpdateSubscriptionSuccess(type, response))
        );
    }

    private handleUpdateSubscriptionSuccess(actionType: string, response: any): ResolvedAction | RejectedAction {
        if (response instanceof HttpErrorResponse) {
            return new RejectedAction(actionType);
        }
        else {
            if (response.success) {
                return new ResolvedAction(actionType);
            }
            else {
                return new RejectedAction(actionType);
            }
        }
    }
}