import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { ProductListState } from './product.models';
import { resolved, rejected, RejectedAction, ResolvedAction } from '@app/core/features/base';
import { actionTypes } from './product.actions';

const initialState: ProductListState = {
  data: [],
  product: null,
  isFetching: false,
  isProductDetailFetching: false,
  error: null
};

export const reducer = handleActions({
  [actionTypes.FETCH_PRODUCTS]: (state: ProductListState) => {
    return update(state, {
      isFetching: { $set: true },
    });
  },
  [resolved(actionTypes.FETCH_PRODUCTS)]: (state: ProductListState, action: ResolvedAction) => {
    const { payload: { data }, meta } = action;

    return update(state, {
      data: { $set: data },
      isFetching: { $set: false },
      error: { $set: null },
    });
  },
  [rejected(actionTypes.FETCH_PRODUCTS)]: (state: ProductListState, action: RejectedAction) => {
    const { payload: { error } } = action;
    return update(state, {
      error: { $set: error },
      isFetching: { $set: false }
    });
  },
  [actionTypes.FETCH_PRODUCT_BY_SKU]: (state: ProductListState) => {
    return update(state, {
      isProductDetailFetching: { $set: true },
      product: { $set: null },
    });
  },
  [resolved(actionTypes.FETCH_PRODUCT_BY_SKU)]: (state: ProductListState, action: ResolvedAction) => {
    const { payload: { data }, meta } = action;

    return update(state, {
      product: { $set: data },
      isProductDetailFetching: { $set: false },
      error: { $set: null },
    });
  },
  [rejected(actionTypes.FETCH_PRODUCT_BY_SKU)]: (state: ProductListState, action: RejectedAction) => {
    const { payload: { error } } = action;

    return update(state, {
      error: { $set: error },
      isProductDetailFetching: { $set: false }
    });
  }
}, initialState);
