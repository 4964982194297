import { Observable, of, throwError } from 'rxjs';
import { endpoints } from '@app/constants';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SubscriptionResponse, FetchSubscriptionsPayload, UpdateSubscriptionResponse, UpdateSubscriptionPayload, UpdateListSubscriptionsResponse  } from './plan-subscription.models';
import { HttpService } from '@app/core/services/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(
    private httpService: HttpService,
  ) { }

  public getDataSubscription = (payload: FetchSubscriptionsPayload): Observable<SubscriptionResponse> => {
    return this.httpService
      .post({ url: `${endpoints.zuora}${endpoints.subscription}${endpoints.list}`, body: payload})
      .pipe(
        mergeMap(this.handleGetSubscriptionResponse),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateSubscription = (payload: UpdateSubscriptionPayload): Observable<UpdateSubscriptionResponse> => {
    return this.httpService
      .post({ url: `${endpoints.zuora}${endpoints.subscription}${endpoints.update}`, body: payload})
      .pipe(
        mergeMap(this.handleUpdateSubscriptionResponse),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateListSubscriptions = (payload: UpdateSubscriptionPayload[]): Observable<UpdateListSubscriptionsResponse> => {
    return this.httpService
      .put({ url: `${endpoints.zuora}${endpoints.subscriptions}`, body: payload })
      .pipe(
        mergeMap(this.handleUpdateListSubscriptionsResponse),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private handleGetSubscriptionResponse(response: SubscriptionResponse): Observable<SubscriptionResponse> {
    return of(response);
  }

  private handleUpdateSubscriptionResponse(response: UpdateSubscriptionResponse): Observable<UpdateSubscriptionResponse> {
    return of(response);
  }

  private handleUpdateListSubscriptionsResponse(response: UpdateListSubscriptionsResponse): Observable<UpdateListSubscriptionsResponse> {
    return of(response);
  }
}
