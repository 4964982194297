import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { State } from '@app/core/features';
import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { actionTypes, GetAnAccountSummary, GetListCreditCards, GetListZuoraAccount } from './account-zuora.actions';
import { AccountZuoraService } from './account-zuora.service';

@Injectable()
export class AccountZuoraEffects extends BaseEffects {
  constructor(
    protected actions: Actions,
    protected store: Store<State>,
    private accountZuoraService: AccountZuoraService,
  ) {
    super();
  }

  @Effect()
  getAnAccountSummary: Observable<Action> = this.takeAction<GetAnAccountSummary>(
    actionTypes.GET_AN_ACCOUNT_SUMMARY,
    this.handleGetAnAccountSummary,
  );

  private handleGetAnAccountSummary(action: GetAnAccountSummary): Observable<ResolvedAction | RejectedAction> {
    const { type, payload, meta } = action;
    return this.handleCommonApiRequest(type, meta)(this.accountZuoraService.getAnAccountSummary, payload);
  }

  @Effect()
  getListZuoraAccount: Observable<Action> = this.takeAction<GetListZuoraAccount>(
    actionTypes.GET_LIST_ZUORA_ACCOUNT,
    this.handleGetListZuoraAccount,
  );

  private handleGetListZuoraAccount(action: GetListZuoraAccount): Observable<ResolvedAction | RejectedAction> {
    const { type } = action;
    return this.handleCommonApiRequest(type)(this.accountZuoraService.getListZuoraAccount);
  }

  @Effect()
  getListCreditCards: Observable<Action> = this.takeAction<GetListCreditCards>(
    actionTypes.GET_LIST_CREDIT_CARDS,
    this.handleGetListCreditCards,
  );

  private handleGetListCreditCards(action: GetListCreditCards): Observable<ResolvedAction | RejectedAction> {
    const { type, payload, meta } = action;
    return this.handleCommonApiRequest(type, meta)(this.accountZuoraService.getListCreditCards, payload);
  }
}
