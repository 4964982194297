import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseEffects, RejectedAction, ResolvedAction } from '../base';
import { actionTypes, FetchListMaintenanceModeFlag } from './maintenance-mode.actions';
import { MaintenanceModeServices } from './maintenance-mode.service';

@Injectable()
export class MaintenanceModeEffects extends BaseEffects {
    constructor(
        protected actions: Actions,
        private maintenanceModeServices: MaintenanceModeServices,
    ) {
        super();
    }

    @Effect()
    fetchListMaintenanceModeFlag: Observable<Action> = this.takeAction<FetchListMaintenanceModeFlag>(
        actionTypes.FETCH_LIST_MAINTENANCE_MODE_FLAG,
        this.handleFetchListMaintenanceModes
    );

    private handleFetchListMaintenanceModes(action: FetchListMaintenanceModeFlag): Observable<ResolvedAction | RejectedAction> {
        const { type } = action;
        return this.handleCommonApiRequest(type)(this.maintenanceModeServices.getListMaintenanceModeFlag);
    }
}