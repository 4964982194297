import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { rejected, RejectedAction, resolved, ResolvedAction } from '../base';
import { actionTypes } from './maintenance-mode.actions';
import { MaintenanceModeState } from './maintenance-mode.models';

const initialState: MaintenanceModeState = {
    data: [],
    error: null
};

export const reducer = handleActions({
    [resolved(actionTypes.FETCH_LIST_MAINTENANCE_MODE_FLAG)]: (state: MaintenanceModeState, action: ResolvedAction) => {
        const { payload: { data } } = action;

        return update(state, {
            data: { $set: data },
            error: { $set: null }
        });
    },
    [rejected(actionTypes.FETCH_LIST_MAINTENANCE_MODE_FLAG)]: (state: MaintenanceModeState, action: RejectedAction) => {
        const { payload: { error } } = action;
        update(state, {
            data: { $set: [] },
            error: { $set: error }
        })
    },
}, initialState);