import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { environment } from '@env/index';

import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducerProvider, reducerToken, metaReducers, effects } from './features';

const storeDevToolsConfig = {
  name: 'Chart Manager Panel',
  logOnly: environment.production
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(
      reducerToken,
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        },
        metaReducers
      }
    ),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', routerState: RouterState.Minimal, }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument(storeDevToolsConfig),
  ],
  providers: [
    reducerProvider
  ]
})
export class CoreModule { }
