import { getRelativePath } from '@app/utils';
import { ObjectWithStringValues } from '@app/core/models';

const HOME = '';
const MY_CHART = 'my-chart';
const SIGN_IN = 'sign-in';
const CHART_SELECTOR = 'chart-selector';
const NO_PURCHASE = 'no-purchase'; // TODO: will remove when all workflow working correctly
const DOWNLOAD_CHART = 'download-charts';
const IMPROVE_CHART = 'improve-charts';
const CHARTS_MANAGER = 'charts-manager';
const LIGHT_HOUSE_CHART = 'lighthouse-charts';
const MAP_BASED_SELECTOR = 'map-based-selector';
const LIST_BASED_SELECTOR = 'list-based-selector';
const AXIOM_PAGE = 'axiom-page';
const DETAIL_CHARTS_STORE = 'detail-chart-store';
const PAGE_NOT_FOUND = 'page-not-found';
const REDEEM_VOUCHER = 'redeem-voucher';
const REDEEM_VOUCHER_SUCCESS = 'redeem-voucher-success';
const FREE_CHART_SELECTOR = 'free-chart-selector';
const DOWNLOAD_RAYCONNECT = 'download-rayconnect';
const MOBILE = 'mobile';
const SUPPORT = 'support';
const HEALTH = 'health';
const OVERVIEW = 'overview';
const HOW_TO_GUIDE = 'how-to-guide';
const ACCEPT_CHARTS = 'accept-chart';
const VERSION = 'version';
const MY_ACCOUNT = 'my-account';
const PLANS_SUBSCRIPTIONS = 'plans-subscriptions';
const UPGRADE_CHARTS = 'upgrade-charts';
const PAYMENT = 'payment';
const CHECKOUT = 'checkout';

export const ID_PARAM_NAME = 'id';
export const ENTITLEMENT_ID_PARAM_NAME = 'entitlementId';
export const PRODUCT_ID_PARAM_NAME = 'productId';
export const CHART_ENTITLEMENT = 'chartEntitlementId';
export const AOI_TYPE = 'aoiType';
const ID = `:${ID_PARAM_NAME}`;

export const paths: ObjectWithStringValues = {
  HOME,
  ID,
  MY_CHART,
  SIGN_IN,
  NO_PURCHASE,
  DOWNLOAD_CHART,
  IMPROVE_CHART,
  CHARTS_MANAGER,
  LIGHT_HOUSE_CHART,
  MAP_BASED_SELECTOR,
  CHART_SELECTOR,
  LIST_BASED_SELECTOR,
  AXIOM_PAGE,
  DETAIL_CHARTS_STORE,
  PAGE_NOT_FOUND,
  REDEEM_VOUCHER,
  REDEEM_VOUCHER_SUCCESS,
  FREE_CHART_SELECTOR,
  DOWNLOAD_RAYCONNECT,
  MOBILE,
  SUPPORT,
  HEALTH,
  OVERVIEW,
  HOW_TO_GUIDE,
  ACCEPT_CHARTS,
  VERSION,
  UPGRADE_CHARTS,
  MY_ACCOUNT,
  PLANS_SUBSCRIPTIONS,
  PAYMENT,
  CHECKOUT
};

function getRelativePaths(): ObjectWithStringValues {
  return Object.entries(paths).reduce(
    (result: ObjectWithStringValues, [key, value]): ObjectWithStringValues => {
      result[key] = getRelativePath(value);
      return result;
    }, {}
  );
}

export const relativePaths: ObjectWithStringValues = getRelativePaths();
