import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { resolved, rejected, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { CheckoutState } from './checkout.models';
import { actionTypes } from './checkout.actions';

const initialState: CheckoutState = {
    ratePlanList: null,
    provisioningStatus: null,
    currenciesAndTaxes: null,
    previewOrderResult : null,
    isFetching: false,
    error: null,
};

export const reducer = handleActions({
    [actionTypes.GET_RATE_PLAN_LIST]: (state: CheckoutState) => {
      return update(state, {
        isFetching: { $set: true },
      });
    },
    [resolved(actionTypes.GET_RATE_PLAN_LIST)]: (state: CheckoutState, action: ResolvedAction) => {
      const { payload: { data } } = action;

      return update(state, {
        ratePlanList: { $set: data },
        isFetching: { $set: false },
        error: { $set: null },
      });
    },
    [rejected(actionTypes.GET_RATE_PLAN_LIST)]: (state: CheckoutState, action: RejectedAction) => {
      const { payload: { error } } = action;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false }
      });
    },
    [resolved(actionTypes.GET_STATUS_PROVISIONING)]: (state: CheckoutState, action: ResolvedAction) => {
      const { payload: { data } } = action;

      return update(state, {
        provisioningStatus: { $set: data }
      });
    },
    [resolved(actionTypes.GET_LIST_CURRENCIES_AND_TAXES)]: (state: CheckoutState, action: ResolvedAction) => {
      const { payload: { data } } = action;

      return update(state, {
        currenciesAndTaxes: { $set: data },
      });
    },
    [resolved(actionTypes.GET_PREVIEW_ORDER)]: (state: CheckoutState, action: ResolvedAction) => {
      const { payload: { data } } = action;

      return update(state, {
        previewOrderResult: { $set: data }
      });
    },
}, initialState);
