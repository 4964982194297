import {Injectable} from '@angular/core';
import {Store, Action} from '@ngrx/store';
import {Actions, Effect} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {State} from '@app/core/features';
import {BaseEffects, ResolvedAction, RejectedAction} from '@app/core/features/base';
import {ChartsStoreService} from './charts-store.service';
import {
  ChartsStoreActions,
  actionTypes
} from './charts-store.actions';

@Injectable()
export class ChartsStoreEffects extends BaseEffects {
  constructor(private chartsStoreService: ChartsStoreService,
              protected actions: Actions,
              protected store: Store<State>) {
    super();
  }

  @Effect()
  chartsStoreActions: Observable<Action> = this.takeAction<ChartsStoreActions>(
    actionTypes.CHARTS_STORE,
    this.handleGetData,
  );

  private handleGetData(action: ChartsStoreActions): Observable<ResolvedAction | RejectedAction> {
    const {type, payload} = action;
    return this.handleCommonApiRequest(type)(this.chartsStoreService.getData, payload);
  }
}


