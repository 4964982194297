import {State} from '@app/core/features';
import {
  ChartsStoreState,
  ChartsStoreResponse
} from './charts-store.models';
import {createSelector, select} from '@ngrx/store';

const getLocalState = (state: State): ChartsStoreState => state.chartsStoreState;

const isFetching = createSelector(
  getLocalState,
  (state: ChartsStoreState): boolean => state.isFetching
);

const getData  = createSelector(
  getLocalState,
  (state: ChartsStoreState): ChartsStoreResponse => state.data
);

const isEmpty = createSelector(
  getLocalState,
  (state: ChartsStoreState): boolean => state.isEmpty
);

export const selectors = {
  getData: select(getData),
  isFetching: select(isFetching),
  isEmpty: select(isEmpty)
};
