import { environment } from '@env/index';

export const getFullUrl = (url: string, path: string): string => `${url}${getRelativePath(path)}`;

// HACK: getRelativePath declared as function declaration to avoid AOT compiler issues
export function getRelativePath(path: string): string {
  return `/${path}`;
}

export const getStaticImagePath = (id: string, isActive: boolean): string =>
  id
    ? `${environment.imageUrl}${id}`
    : isActive
      ? '../assets/svgs/default_active_image.svg'
      : '../assets/svgs/default_inactive_image.svg';

export const getStaticMonikerImagePath = (id: string, isComingSoon: boolean): string =>
  id
    ? `${environment.imageUrl}${environment.monikerImageUrl}${id}`
    : !isComingSoon
      ? '../assets/svgs/default_active_image.svg'
      : '../assets/svgs/default_inactive_image.svg';
