import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/core/models';
import { FeedbackDetailPayload } from './auth.models';

export const actionTypes: ObjectWithStringValues = {
  FETCH_LOGGED_IN_USER: 'FETCH_LOGGED_IN_USER',
  FETCH_FEEDBACK_DETAIL: 'FETCH_FEEDBACK_DETAIL',
};

export class FetchLoggedInUser implements Action {
  readonly type = actionTypes.FETCH_LOGGED_IN_USER;
  constructor() {}
}

export class FetchFeedbackDetail implements Action {
  readonly type = actionTypes.FETCH_FEEDBACK_DETAIL;
  constructor(public payload: FeedbackDetailPayload) {}
}