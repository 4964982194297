import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { resolved, rejected, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { actionTypes } from './auth.actions';
import { AuthState, UserData } from './auth.models';


const initialState: AuthState = {
    data: null,
    feedbackDetail: null,
    isFetching: false,
    hasError: false,
};

export const reducer = handleActions({
    [actionTypes.FETCH_LOGGED_IN_USER]: (state: AuthState) => {
        return update(state, {
            isFetching: { $set: true },
        });
    },
    [resolved(actionTypes.FETCH_LOGGED_IN_USER)]: (state: AuthState, action: ResolvedAction) => {
        const { payload: { data } } = action;
        return update(state, {
            data: { $set: data },
            isFetching: { $set: false },
            hasError: { $set: false },
        });
    },
    [rejected(actionTypes.FETCH_LOGGED_IN_USER)]: (state: AuthState, action: RejectedAction) => {
        return update(state, {
            hasError: { $set: true },
            isFetching: { $set: false }
        });
    },
    [resolved(actionTypes.FETCH_FEEDBACK_DETAIL)]: (state: AuthState, action: ResolvedAction) => {
        const { payload: { data } } = action;
        return update(state, {
            feedbackDetail: { $set: data }
        });
    },
    [rejected(actionTypes.FETCH_FEEDBACK_DETAIL)]: (state: AuthState, action: RejectedAction) => {
        return update(state, {
            feedbackDetail: { $set: null }
        });
    } 
}, initialState);

