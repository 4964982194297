import { Injectable } from '@angular/core';
import { constants } from '@app/constants';

@Injectable()
export class Globals {
    isAxiom: boolean;
    isActive: boolean;
    isDisplayProgressSpinner: boolean;
    isShowGlobalError: boolean;

    constructor(
    ) {
        this.isAxiom = navigator.userAgent.includes(constants.axiomDeviceString);
        this.isActive = true;
        this.isDisplayProgressSpinner = false;
        this.isShowGlobalError = true;
    }
}
