import * as _ from 'lodash';

import { ObjectWithStringValues } from '@app/shared/models';
import { callSafely } from './common';
import { ObjectWithMultipleStringValues } from '@app/core/models';

const { isFunction } = _;

export const pickModelValues = (data: object, model: object): object => (
    Object.entries(model).reduce((result, item) => {
        const [modelKey, dataKey] = item;
        const modelField = model[modelKey];
        const modelFieldResult = isFunction(modelField) ? callSafely(modelField, data) : data[dataKey];

        if (modelFieldResult !== undefined) {
            result[modelKey] = modelFieldResult;
        }

        return result;
    }, {})
);


// TODO: extract to common
export const convertObjectValuesToString = (object: object): ObjectWithStringValues => (
    Object.entries(object).reduce((result: ObjectWithStringValues, [key, value]) => {
        result[key] = String(value);

        return result;
    }, {})
);

export const convertObjectValuesToListString = (object: object): ObjectWithMultipleStringValues => (
    Object.entries(object).reduce((result: ObjectWithMultipleStringValues, [key, value]) => {
        result[key] = value;

        return result;
    }, {})
);
