import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/shared/models';
import { GetAnAccountSummaryPayload, GetListCreditCardsMeta, GetListCreditCardsPayload } from './account-zuora.models';


export const actionTypes: ObjectWithStringValues = {
  GET_AN_ACCOUNT_SUMMARY: 'GET_AN_ACCOUNT_SUMMARY',
  GET_LIST_ZUORA_ACCOUNT: 'GET_LIST_ZUORA_ACCOUNT',
  GET_LIST_CREDIT_CARDS: 'GET_LIST_CREDIT_CARDS',
  SET_LOADING_LIST_CARD: 'SET_LOADING_LIST_CARD'
};

export class GetAnAccountSummary implements Action {
  readonly type = actionTypes.GET_AN_ACCOUNT_SUMMARY;

  constructor(public payload: GetAnAccountSummaryPayload, public meta: GetListCreditCardsMeta) { }
}

export class GetListZuoraAccount implements Action {
  readonly type = actionTypes.GET_LIST_ZUORA_ACCOUNT;

  constructor() { }
}

export class GetListCreditCards implements Action {
  readonly type = actionTypes.GET_LIST_CREDIT_CARDS;

  constructor(public payload: GetListCreditCardsPayload, public meta: GetListCreditCardsMeta) { }
}

export class SetLoadingListCard implements Action {
  readonly type = actionTypes.SET_LOADING_LIST_CARD;

  constructor() { }
}