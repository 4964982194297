import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { State } from '@app/core/features';
import { Effect, Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { ProductService } from './product.service';
import { actionTypes, FetchProductBySku, FetchProducts } from './product.actions';

@Injectable()
export class ProductsEffects extends BaseEffects {
  constructor(
    protected actions: Actions,
    protected store: Store<State>,
    private productService: ProductService
  ) {
    super();
  }

  @Effect()
  fetchProducts: Observable<Action> = this.takeAction<FetchProducts>(
    actionTypes.FETCH_PRODUCTS,
    this.handleProductsFetch
  );

  @Effect()
  fetchProductBySku: Observable<Action> = this.takeAction<FetchProductBySku>(
    actionTypes.FETCH_PRODUCT_BY_SKU,
    this.handleFetchProductBySku
  );

  private handleProductsFetch(action: FetchProducts): Observable<ResolvedAction | RejectedAction> {
    const { type, payload } = action;
    return this.handleCommonApiRequest(type) (this.productService.fetchProductList, payload);
  }

  private handleFetchProductBySku(action: FetchProductBySku): Observable<ResolvedAction | RejectedAction> {
    const { type, sku } = action;
    return this.handleCommonApiRequest(type) (this.productService.fetchProductBySku, sku);
  }
}
