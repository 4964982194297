import { handleActions } from 'redux-actions';
import { SubscriptionState } from './plan-subscription.models';
import update from 'immutability-helper';
import { actionTypes } from './plan-subscription.action'
import { resolved, rejected, RejectedAction, ResolvedAction } from '@app/core/features/base';

const initialState: SubscriptionState = {
  data: {},
  isFetching: false,
  error: null,
};

export const reducer = handleActions({
    [actionTypes.FETCH_SUBSCRIPTION]: (state : SubscriptionState) => {
        return update(state, {
            isFetching: { $set: true },
        })
    },
    [resolved(actionTypes.FETCH_SUBSCRIPTION)]: (state: SubscriptionState, action: ResolvedAction) => {
        const { payload: { data }, meta: { currency } } = action;
        return update(state, {
            error : { $set: null },
            isFetching: { $set: false },
            data: {
                [currency]: { $set: data }
            }
        });
    },
    [rejected(actionTypes.FETCH_SUBSCRIPTION)]: (state: SubscriptionState, action: RejectedAction) => {
        const { payload: { error } } = action;
        return update(state, {
            error: { $set: error },
            isFetching: { $set: false }
        })
    },
}, initialState)