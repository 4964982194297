import { ObjectWithStringValues } from '@app/core/models';
import { Action } from '@ngrx/store';
import { ProductQuery } from './product.payload';

export const actionTypes: ObjectWithStringValues = {
    FETCH_PRODUCTS: 'FETCH_PRODUCTS',
    FETCH_PRODUCT_BY_SKU: 'FETCH_PRODUCT_BY_SKU'
};

export class FetchProducts implements Action {
    readonly type = actionTypes.FETCH_PRODUCTS;
    constructor(public payload: ProductQuery) { }
}

export class FetchProductBySku implements Action {
    readonly type = actionTypes.FETCH_PRODUCT_BY_SKU;
    constructor(public sku: string) { }
}
