import { Action } from '@ngrx/store';

import { ObjectWithStringValues } from '@app/core/models';

export const resolved = (type: string): string => `${type}_SUCCESS`;
export const rejected = (type: string): string => `${type}_FAILED`;

export const actionTypes: ObjectWithStringValues = {
    START_UP: 'START_UP',
};

export class ResolvedAction implements Action {
    readonly type;

    constructor(type: string, public payload?: { data: any }, public meta?: any) {
        this.type = resolved(type);
    }
}

export class RejectedAction implements Action {
    readonly type;

    constructor(type: string, public payload?: any) {
        this.type = rejected(type);
    }
}
