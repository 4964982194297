import {Action} from '@ngrx/store';
import {ObjectWithStringValues} from '@app/shared/models';
import {
  ChartsStoreRequest
} from './charts-store.models';

export const actionTypes: ObjectWithStringValues = {
  CHARTS_STORE: 'CHARTS_STORE'
};

export class ChartsStoreActions implements Action {
  readonly type = actionTypes.CHARTS_STORE;

  constructor(public payload: ChartsStoreRequest) {
  }
}
