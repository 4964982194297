import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/shared/models';

export const actionTypes: ObjectWithStringValues = {
  MY_CHART_LIST: 'MY_CHART_LIST'
};

export class MyChartList implements Action {
    readonly type = actionTypes.MY_CHART_LIST;

    constructor(public isDealer: Boolean = false) { }
}
