import { Action } from '@ngrx/store';
import { ObjectWithStringValues } from '@app/core/models';
import {
  CreateMyChartItemPayload,
  CreateMyChartItemMeta,
  DeleteChartItemAllPayload,
  DeleteChartItemOnePayload,
  DeleteChartItemsPayload,
  MyChartItemPayload,
  CreateEntirePayload,
  DeleteChartItemOneMeta,
  DeleteChartItemAllMeta,
  ChartEntitlementPayload,
  MigrationOptionsQuery,
  MigrateMergeChartPayload,
  ClearUserDevicePayload,
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';

export const actionTypes: ObjectWithStringValues = {
  GET_CHART_ENTITLEMENT_BY_ID: 'GET_CHART_ENTITLEMENT_BY_ID',
  GET_CHART_ITEM: 'GET_CHART_ITEM',
  CREATE_CHART_ITEM: 'CREATE_CHART_ITEM',
  CREATE_ENTIRE: 'CREATE_ENTIRE',
  DELETE_CHART_ITEM_ONE: 'DELETE_CHART_ITEM_ONE',
  DELETE_CHART_ITEM_ALL: 'DELETE_CHART_ITEM_ALL',
  GET_CHART_ITEMS: 'GET_CHART_ITEMS',
  DELETE_CHART_ITEMS: 'DELETE_CHART_ITEMS',
  SUBMIT_IMPROVE_CHARTS: 'SUBMIT_IMPROVE_CHARTS',
  GET_MIGRATION_OPTIONS: 'GET_MIGRATION_OPTIONS',
  MIGRATE_MERGE_CHART: 'MIGRATE_MERGE_CHART',
  CLEAR_USER_DEVICE: 'CLEAR_USER_DEVICE'
};

export class GetChartEntitlementByIdAction implements Action {
  readonly type = actionTypes.GET_CHART_ENTITLEMENT_BY_ID;
  constructor(public payload: ChartEntitlementPayload) {}
}

export class GetChartItemAction implements Action {
  readonly type = actionTypes.GET_CHART_ITEM;
  constructor(public payload: MyChartItemPayload) {}
}

export class CreateChartItem implements Action {
  readonly type = actionTypes.CREATE_CHART_ITEM;
  constructor(public payload: CreateMyChartItemPayload, public meta: CreateMyChartItemMeta) {}
}

export class CreateEntire implements Action {
  readonly type = actionTypes.CREATE_ENTIRE;
  constructor(public payload: CreateEntirePayload) { }
}

export class DeleteChartItemOne implements Action {
  readonly type = actionTypes.DELETE_CHART_ITEM_ONE;
  constructor(public payload: DeleteChartItemOnePayload, public meta: DeleteChartItemOneMeta) {}
}

export class DeleteChartItemAll implements Action {
  readonly type = actionTypes.DELETE_CHART_ITEM_ALL;
  constructor(public payload: DeleteChartItemAllPayload, public meta: DeleteChartItemAllMeta) {}
}

export class GetChartItemsAction implements Action {
  readonly type = actionTypes.GET_CHART_ITEMS;
  constructor(public payload: MyChartItemPayload) { }
}

export class DeleteChartItems implements Action {
  readonly type = actionTypes.DELETE_CHART_ITEMS;
  constructor(public payload: DeleteChartItemsPayload) { }
}

export class GetMigrationOptions implements Action {
  readonly type = actionTypes.GET_MIGRATION_OPTIONS;
  constructor(public payload: MigrationOptionsQuery) { }
}

export class MigrateMergeChart implements Action {
  readonly type = actionTypes.MIGRATE_MERGE_CHART;
  constructor(public payload: MigrateMergeChartPayload) { }
}

export class ClearUserDevice implements Action {
  readonly type = actionTypes.CLEAR_USER_DEVICE;
  constructor(public payload: ClearUserDevicePayload) { }
}
