import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from '@app/constants/paths';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path: paths.HOME,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled' // Remove this line to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
