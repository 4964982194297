import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export const AppTranslation = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useClass: LazyTranslateLoader,
    deps: [HttpClient],
  },
  useDefaultLang: true,
});
