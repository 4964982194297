import { ObjectWithStringValues } from '@app/core/models'
import { Action } from '@ngrx/store'
import { FetchSubscriptionsMeta, FetchSubscriptionsPayload, UpdateSubscriptionPayload } from './plan-subscription.models'

export const actionTypes : ObjectWithStringValues = {
    FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
    FETCH_SUBSCRIPTION_SUCCESS: 'FETCH_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILED: 'UPDATE_SUBSCRIPTION_FAILED',
}

export class FetchSubscriptionsAction implements Action {
    readonly type = actionTypes.FETCH_SUBSCRIPTION
    constructor(public payload: FetchSubscriptionsPayload, public meta: FetchSubscriptionsMeta) { }
}

export class UpdateSubscription implements Action {
    readonly type = actionTypes.UPDATE_SUBSCRIPTION
    constructor(public payload: UpdateSubscriptionPayload) { }
}
