import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { endpoints, areaOfInterestType } from '@app/constants';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  CreateMyChartItemPayload,
  DeleteChartItemOnePayload,
  CreateEntirePayload,
  MyChartItemChartData,
  MyChartItemPayload,
  DeleteChartItemAllPayload,
  MyChartItemCapacity,
  ChartEntitlementPayload,
  chartInformationResponseConvertSchema,
  MigrationOptionsQuery,
  MigrationOptionsResponse,
  MigrationOption,
  MigrateMergeChartPayload,
  ClearUserDevicePayload,
  MFDTypePayload,
  smallChartDownloadedAcknowledgedPayload,
} from '@app/core/features/charts-manager/my-charts/my-chart-item/my-chart-item.models';
import { CapacityModel } from '@app/core/features/charts-manager/my-charts';
import { MyChartsListResponse, removeLighthouseText } from '../my-charts-list';
import { convertObjectValuesToListString, pickModelValues } from '@app/utils';

@Injectable({
  providedIn: 'root'
})
export class MyChartItemService {
  // List Observable capacity of Chart data, POI, Aerial photos
  private listCapacity: MyChartItemCapacity[] = [];
  constructor(
    private http: HttpService,
  ) {
    Object.keys(areaOfInterestType)
      .filter(value => isNaN(Number(value)))
      .map(enumKey => {
        this.listCapacity.push({
          capacity: new BehaviorSubject<CapacityModel[]>([]),
          areaOfInterestType: Number(areaOfInterestType[enumKey])
        });
      });
  }

  private findCapacity(aoiType: areaOfInterestType): BehaviorSubject<CapacityModel[]> {
    return this.listCapacity.find(item => item.areaOfInterestType === aoiType).capacity;
  }

  // Observable capacity stream
  getCapacity(aoiType: areaOfInterestType): Observable<CapacityModel[]> {
    return this.findCapacity(aoiType).asObservable();
  }

  // Get capacity last value
  getCapacityValue(aoiType: areaOfInterestType): CapacityModel[] {
    return this.findCapacity(aoiType).value;
  }

  // Update the capacity
  setCapacity(listCapacity: CapacityModel[], aoiType: areaOfInterestType): void {
    this.findCapacity(aoiType).next(listCapacity);
  }

  getChartEntitlementById = (payload: ChartEntitlementPayload): Observable<MyChartsListResponse> => {
    const { chartEntitlementId } = payload;
    return this.http
      .get({url: endpoints.getChartEntitlementById + chartEntitlementId})
      .pipe(
        mergeMap(response => this.handleGetChartEntitlementByIdResponse(response)),
        catchError((error) =>  of(error))
      );
  }

  getChartItem = (payload: MyChartItemPayload): Observable<MyChartItemChartData[]> => {
    const {chartEntitlementId, aoiType} = payload;
    return this.http
      .get({url: endpoints.chartItemData + chartEntitlementId})
      .pipe(
        mergeMap((response: MyChartItemChartData[]) => this.handleGetChartItemResponse(response, aoiType)),
        catchError((error) =>  of(error))
      );
  }

  getChartItems = (payload: MyChartItemPayload): Observable<object> => {
    const { chartEntitlementId } = payload;
    return this.http
      .get({ url: endpoints.chartItemData + chartEntitlementId })
      .pipe(
        mergeMap(this.handleGetChartItemsResponse),
        catchError((error) => of(error))
      );
  }

  postChartItem = (payload: CreateMyChartItemPayload): Observable<object> => {
    return this.http
      .post({url: endpoints.postItemData, body: payload})
      .pipe(
        mergeMap(this.handleCreateChartItemResponse),
        catchError(error => of(error))
      );
  }

  createEntireChartItem = (payload: CreateEntirePayload): Observable<object> => {
    return this.http
      .post({ url: endpoints.chartItemData, body: payload })
      .pipe(
        mergeMap(response => of(response)),
        catchError(error => of(error))
      );
  }

  deleteOneChartItem = (payload: DeleteChartItemOnePayload): Observable<object> => {
    const {id} = payload;
    return this.http
      .remove({url: endpoints.updateItemData + id})
      .pipe(
        mergeMap(this.handleRemoveChartItemResponse),
        catchError(error => of(error))
      );
  }

  deleteAllChartItem = (payload: DeleteChartItemAllPayload): Observable<any> => {
    const {ids} = payload;
    return this.http
      .post({ url: endpoints.deleteManyChartItems, body: ids })
      .pipe(
        mergeMap(this.handleDeleteChartItemResponse),
        catchError((err) => of(err))
      );
  }

  deleteChartItems = (ids: string[]): Observable<any> => {
    return this.http
      .post({ url: endpoints.deleteManyChartItems, body: ids })
      .pipe(
        mergeMap(this.handleDeleteChartItemResponse),
        catchError((err) => of(err))
      );
  }

  submitImproveCharts = (file: FormData): Observable<object> => {
    return this.http
      .postWithFile({url: `${endpoints.chartFeedback}${endpoints.addChartFeedback}`, body: file})
      .pipe(
        mergeMap(this.handleSubmitImproveChartsResponse),
        catchError(error => of(error))
      );
  }

  getMigrationOptions = (query: MigrationOptionsQuery): Observable<MigrationOptionsResponse> => {
    const convertedQuery = convertObjectValuesToListString(query);

    return this.http.setParams(convertedQuery).get({ url: endpoints.getMigrationOptions })
      .pipe(
        mergeMap(this.handleGetMigrationOptionsResponse),
        catchError((error) => of(error))
      );
  }

  migrateMergeChart = (query: MigrateMergeChartPayload): Observable<any> => {
    const convertedQuery = convertObjectValuesToListString(query);

    return this.http.setParams(convertedQuery)
      .post({ url: endpoints.migrateMergeChart })
      .pipe(
        mergeMap(this.handleMigrateMergeChartResponse),
        catchError(error => of(error))
      );
  }

  clearUserDevice = (payload: ClearUserDevicePayload): Observable<any> => {
    return this.http
    .post({url: endpoints.clearUserDevice, body: payload})
    .pipe(
      mergeMap(this.handleClearUserDevice),
      catchError(error => of(error))
    );
  }

  putMFDType = (payload: MFDTypePayload): Observable<object> => {
    return this.http
      .put({ url: endpoints.getMFDType, body: payload })
      .pipe(
        mergeMap(this.handleMFDTypeResponse),
        catchError(error => of(error))
      );
  }

  updateSmallChartDownloadedAcknowledged = (payload: smallChartDownloadedAcknowledgedPayload): Observable<object> => {
    return this.http
      .put({ url: endpoints.updateSmallChartDownloadedAcknowledged, body: payload })
      .pipe(
        mergeMap(this.handleUpdateSmallChartDownloadedAcknowledgedResponse),
        catchError(error => of(error))
      );
  }

  handleGetChartItemsResponse = (response): Observable<MyChartItemChartData[]> => {
    return of(response);
  }

  handleGetChartItemResponse = (response: MyChartItemChartData[], aoiType: areaOfInterestType): Observable<MyChartItemChartData[]> => {
    const chartData: MyChartItemChartData[] = [];
    response.map(item => {
      if (item.areaOfInterestType === aoiType) {
        item.aerialsDetail = item.aerialsDetail ? item.aerialsDetail : null;
        chartData.push(item as MyChartItemChartData);
      }
    });
    return of(chartData);
  }

  handleCreateChartItemResponse = (response): Observable<object> => {
    return of(response);
  }

  handleRemoveChartItemResponse = (): Observable<boolean> => {
    return of(true);
  }

  private handleDeleteChartItemResponse(): Observable<any> {
    return of({});
  }

  handleGetChartEntitlementByIdResponse = (response): Observable<object> => {
    const chartEntitlement = pickModelValues(response, chartInformationResponseConvertSchema);

    return of(chartEntitlement);
  }

  handleSubmitImproveChartsResponse = (response): Observable<object> => {
    return of(response);
  }

  handleGetMigrationOptionsResponse = (response: MigrationOptionsResponse): Observable<any> => {
    const migrationOption: MigrationOption[] = [];
    const separateCharts = {
      isRegion: false,
      chartContentsList: [],
      chartSizeKB: 0,
      sku: null,
      chartName: ''
    };
    const listName = [];
    response.migrationOptions.forEach(item => {
      if (!item.isRegion) {
        const chart = {
          chartName: removeLighthouseText(item.chartName),
          sku: item.sku,
        }
        separateCharts.chartContentsList.push(chart);
        listName.push(removeLighthouseText(item.chartName));
        separateCharts.chartSizeKB += item.chartSizeKB;
      } else {
        migrationOption.push(item)
      }
    });
    separateCharts.chartName = listName.join(' & ');
    migrationOption.push(separateCharts);

    return of(migrationOption);
  }

  handleMigrateMergeChartResponse = (response): Observable<object> => {
    return of(response);
  }

  handleClearUserDevice = (response): Observable<object> => {
    return of(response);
  }

  handleMFDTypeResponse = (response): Observable<object> => {
    return of(response);
  }

  handleUpdateSmallChartDownloadedAcknowledgedResponse = (response): Observable<object> => {
    return of(response);
  }
}
