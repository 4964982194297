import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { Observable, of } from 'rxjs';
import { endpoints } from '@app/constants';
import { catchError, mergeMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BaseSelectorService {
  constructor(
    private http: HttpService,
  ) { }

  toggleFirstTimeDraw = (): Observable<object> => {
    return this.http
      .post({url: endpoints.toggleFirstTimeDraw})
      .pipe(
        mergeMap(this.handleToggleFirstTimeDraw),
        catchError((err) => of(err))
      );
  }

  toggleFirstTimeReachBoxLimit = (): Observable<object> => {
    return this.http
      .post({url: endpoints.toggleFirstTimeReachBoxLimit})
      .pipe(
        mergeMap(this.handleToggleFirstTimeReachBoxLimit),
        catchError((err) => of(err))
      );
  }

  private handleToggleFirstTimeDraw(): Observable<any> {
    return of({});
  }

  private handleToggleFirstTimeReachBoxLimit(): Observable<any> {
    return of({});
  }
}
