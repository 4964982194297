import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap, catchError, map } from 'rxjs/operators';

import { ResolvedAction, RejectedAction } from './base.actions';
import { BackEndResponse } from './base.models';

type CommonServiceMethod = (...args: any[]) => Observable<object>;

type CommonApiRequestHandler = (serviceMethod: CommonServiceMethod, ...args: any[]) => (
    Observable<ResolvedAction | RejectedAction>
);

type ActionHandler = (action?: Action) => Observable<Action>;

// TODO: add types for handler functions
export abstract class BaseEffects {
    protected abstract actions: Actions;

    takeOfType<T extends Action>(type: string): Observable<Action> {
        return this.actions.pipe(
            ofType<T>(type),
        );
    }

  // tslint:disable-next-line:ban-types
    takeAction<T extends Action>(type: string, handler: ActionHandler): Observable<Action> {
      // @ts-ignore
      return this.takeOfType<T>(type).pipe(
            mergeMap((action: T) => handler.call(this, action))
        );
    }

  // tslint:disable-next-line:ban-types
    takeLatestAction<T extends Action>(type: string, handler: Function): Observable<Action> {
      // @ts-ignore
      return this.takeOfType<T>(type).pipe(
            switchMap((action: T) => handler.call(this, action))
        );
    }

  // tslint:disable-next-line:ban-types
    takeActionSilently<T extends Action>(type: string, handler: Function): Observable<Action> {
        return this.takeOfType<T>(type).pipe(
            tap((action: T) => handler.call(this, action))
        );
    }

    handleSuccessResponse(actionType: string, response: any, meta?: any): ResolvedAction | RejectedAction {
        if (response instanceof HttpErrorResponse) {
            const backEndError = response.error as BackEndResponse;
            const message = backEndError ? backEndError.Message : response.message;

            return new RejectedAction(actionType, { error: message });
        }

        return new ResolvedAction(actionType, { data: response }, meta);
    }

    handleFailedResponse(actionType: string, error?: Error): Observable<RejectedAction> {
        return of(new RejectedAction(actionType, { error: error && error.message }));
    }

    handleCommonApiRequest(actionType: string, meta = {}): CommonApiRequestHandler {
        return (serviceMethod: CommonServiceMethod, ...args: any[]) => serviceMethod(...args).pipe(
            map((response) => this.handleSuccessResponse(actionType, response, meta)),
            catchError((error) => this.handleFailedResponse(actionType, error))
        );
    }

}
