import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { State } from '@app/core/features';
import { BaseEffects, ResolvedAction, RejectedAction } from '@app/core/features/base';
import { BaseSelectorService } from './base-selector.service';
import {
  actionTypes,
  ToggleFirstTimeDraw,
  ToggleFirstTimeReachBoxLimit
} from './base-selector.actions';

@Injectable()
export class BaseSelectorEffects extends BaseEffects {
  constructor(
    private baseSelectorService: BaseSelectorService,
    protected actions: Actions,
    protected store: Store<State>
  ) {
    super();
  }

  @Effect()
  toggleFirstTimeDraw: Observable<Action> = this.takeAction<ToggleFirstTimeDraw>(
    actionTypes.TOGGLE_FIRST_TIME_DRAW,
    this.handleToggleFirstTimeDraw,
  );

  @Effect()
  toggleFirstTimeReachBoxLimit: Observable<Action> = this.takeAction<ToggleFirstTimeReachBoxLimit>(
    actionTypes.TOGGLE_FIRST_TIME_REACH_BOX_LIMIT,
    this.handleToggleFirstTimeReachBoxLimit,
  );

  private handleToggleFirstTimeDraw(action: ToggleFirstTimeDraw): Observable<ResolvedAction | RejectedAction> {
    const { type } = action;
    return this.handleCommonApiRequest(type)(this.baseSelectorService.toggleFirstTimeDraw);
  }

  private handleToggleFirstTimeReachBoxLimit(action: ToggleFirstTimeReachBoxLimit): Observable<ResolvedAction | RejectedAction> {
    const { type } = action;
    return this.handleCommonApiRequest(type)(this.baseSelectorService.toggleFirstTimeReachBoxLimit);
  }
}


