import { ZuoraCurrenciesModel } from "@app/core/features/payment";

export const LightHouseChartProductSku = 'SKU-00000008';

export const ZUORA_CART = {
    USER_ID: 'zuora_customer_id',
    LIST_PRODUCT: 'zuora_list_product'
};

export enum ProvisioningStatus {
    Processing = 0,
    Successful = 1,
    Failed = 2
};

export const ZuoraSupportedCardType = 'Visa,MasterCard';

export const ZuoraSupportedCardTypeForUSD = `${ZuoraSupportedCardType},AmericanExpress`;

export const ZuoraCurrencyUSD = 'USD';


export const CountryBlackList = ["AFG","BLR","BDI","KHM","CAF","CHN","COD","COG","CUB","CYP","ERI","ETH","GNB","HTI","HKG","IRN","IRQ","PRK","LBN","LBY","MAC","MMR","RUS","RWA","SOM","SSD","SDN","SYR","TZA","UGA","UKR","VEN","YEM","ZMB","ZWE"]

export const ZuoraCurrencies: ZuoraCurrenciesModel[] = [
    { unit: '$', currency: 'CAD', name: 'Canadian dollar' },
    { unit: '€', currency: 'EUR', name: 'Euro' },
    { unit: '£', currency: 'GBP', name: 'British pound' },
    { unit: '$', currency: 'USD', name: 'United States dollar' },
  ];

export const CodeAndErrorMessage = {
    refusedStoreConsentAgreement: {
        code: '52800099',
        message: '65: REFUSED'
    }
};