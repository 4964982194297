import { State } from '@app/core/features';
import { createSelector, select } from '@ngrx/store';
import { AuthState, FeedbackDetail, UserData } from './auth.models';

const getLocalState = (state: State): AuthState => state.auth;

const isFetching = createSelector(
  getLocalState,
  (state: AuthState): boolean => state.isFetching
);

const getUserData  = createSelector(
  getLocalState,
  (state: AuthState): UserData => state.data
);

const getFeedbackDetail  = createSelector(
  getLocalState,
  (state: AuthState): FeedbackDetail => state.feedbackDetail
);

const getHasError = createSelector(
  getLocalState,
  (state: AuthState): boolean => state.hasError
);

export const selectors = {
  getUserData: select(getUserData),
  getFeedbackDetail: select(getFeedbackDetail),
  isFetching: select(isFetching),
  getHasError: select(getHasError)
};
