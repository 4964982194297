import { ObjectWithStringValues } from '@app/core/models';
import { Action } from '@ngrx/store';

export const actionTypes: ObjectWithStringValues = {
    FETCH_LIST_MAINTENANCE_MODE_FLAG: 'FETCH_LIST_MAINTENANCE_MODE_FLAG',
};

export class FetchListMaintenanceModeFlag implements Action {
    readonly type = actionTypes.FETCH_LIST_MAINTENANCE_MODE_FLAG;
    constructor() { }
}